import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import InputMask, { ReactInputMask } from 'react-input-mask'

import CloseIcon from '@mui/icons-material/Close'
import { forwardRef, useContext, useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { enqueueSnackbar } from 'notistack'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { companyBranchServices } from '../../services/companyBranchServices'

const schema = z.object({
  nome: z.string().min(1, 'Campo obrigatório'),
  empresa_filha: z.string().min(1, 'Campo obrigatório'),
  situacao: z.string().min(1, 'Campo obrigatório')
})

type Schema = z.infer<typeof schema>

interface CompanyFormProps {
  formData: any
  setOpen: (value: boolean) => void
}

const TextMaskCustom = forwardRef<HTMLElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <InputMask
      {...other}
      mask="99.999.999/9999-99"
      maskChar={null}
      inputRef={ref}
      onChange={onChange}
    />
  )
})

export const CompanyBranchForm = ({ formData, setOpen }: CompanyFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const queryClient = useQueryClient()
  const auth = useContext(AuthContext)

  const { mutate: putCompanyBranch, isLoading: isLoadingPut } = useMutation(
    async (data: Schema) => {
      return await companyBranchServices.putCompanyBranch(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companyBranch')
        enqueueSnackbar('Salvo com sucesso!', { variant: 'success' })
        setOpen(false)
      }
    }
  )

  const { mutate: postCompanyBranch, isLoading: isLoadingPost } = useMutation(
    async (data: Schema) => {
      return await companyBranchServices.postCompanyBranch(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companyBranch')
        enqueueSnackbar('Salvo com sucesso!', { variant: 'success' })
        setOpen(false)
      }
    }
  )

  const onSubmit = (data: Schema) => {
    const payloadCreate = {
      ...data,
      empresa: auth.user?.empresa
    }

    const payloadPut = {
      ...data,
      id: formData.id,
      empresa: auth.user?.empresa
    }

    formData?.id ? putCompanyBranch(payloadPut) : postCompanyBranch(payloadCreate)
  }

  const empresasFilhas = queryClient.getQueryData<any>('companies')

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle variant="h4" sx={{ mt: 2 }}>
        {` ${formData?.id ? 'Edição de filial' : 'Cadastro de filial'}`}
      </DialogTitle>
      <IconButton
        sx={{ position: 'absolute', right: 15, top: 25 }}
        onClick={() => {
          setOpen(false)
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Nome"
                variant="outlined"
                fullWidth
                defaultValue={formData?.nome || ''}
                {...register('nome')}
                error={!!errors.nome}
                helperText={errors.nome?.message}
                key={formData.nome}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Empresa"
                variant="outlined"
                fullWidth
                select
                defaultValue={formData.empresa_filha?.id}
                {...register('empresa_filha')}
                error={!!errors.empresa_filha}
                helperText={errors.empresa_filha?.message}>
                {empresasFilhas?.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.apelido}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Situação"
                variant="outlined"
                fullWidth
                select
                defaultValue={formData.situacao}
                {...register('situacao')}
                error={!!errors.situacao}
                helperText={errors.situacao?.message}>
                <MenuItem value="ATIVO">Ativo</MenuItem>
                <MenuItem value="INATIVO">Inativo</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  mr: 2,
                  '&.Mui-focusVisible': {
                    transform: 'scale(1.05)',
                    transition: 'all 0.3s'
                  }
                }}
                type="submit">
                Salvar
              </Button>
              <Button
                variant="outlined"
                sx={{
                  '&.Mui-focusVisible': {
                    transform: 'scale(1.05)',
                    transition: 'all 0.3s'
                  }
                }}
                onClick={() => setOpen(false)}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
