import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Paper,
  styled,
  Typography,
  useTheme,
  Skeleton
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import AssistantIcon from '@mui/icons-material/AssistantOutlined'
import { useState } from 'react'
import { THighlights } from '../../../types/highlights'

interface HighlightsProps {
  loading?: boolean
  type?: string
  diagnosticoSectorData?: THighlights[]
  helpServiceData?: THighlights[]
}

interface CardItemProps {
  type: string
  item: THighlights
}

export const Highlights = (props: HighlightsProps) => {
  const { loading, diagnosticoSectorData, helpServiceData } = props
  const theme = useTheme()
  const [isExpand, setIsExpand] = useState(false)

  const AvatarWrapperError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color:  ${theme.colors.error.main};
      width: 40px;
      height: 40px;
`
  )

  const AvatarWrapperSecondary = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.secondary.lighter};
      color:  ${theme.colors.secondary.main};
      width: 40px;
      height: 40px;
`
  )

  const AvatarWrapperSuccess = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.success.lighter};
        color:  ${theme.colors.success.main};
        width: 40px;
        height: 40px;
  `
  )

  const AvatarWrapperWarning = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.warning.lighter};
        color:  ${theme.colors.warning.main};
        width: 40px;
        height: 40px;
  `
  )

  const Header = () => {
    return (
      <CardHeader
        title="Highlights"
        action={
          <IconButton
            aria-label="settings"
            onClick={() => {
              setIsExpand(!isExpand)
            }}
            sx={{
              border: '1px solid',
              borderColor: theme.palette.primary.main,
              borderRadius: 1,
              height: 30
            }}>
            <Typography sx={{ color: theme.palette.primary.main, fontWeight: 700 }}>
              Todos
            </Typography>
            {!isExpand ? (
              <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
            ) : (
              <ExpandLessIcon sx={{ color: theme.palette.primary.main }} />
            )}
          </IconButton>
        }
      />
    )
  }

  const CardItem = (props: CardItemProps) => {
    const { item, type } = props

    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: { md: 'row', xs: 'column' },
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: { sm: 'left', xs: 'center' },
          gap: 2,
          px: 2,
          minHeight: { md: 110, xs: 220 }
        }}>
        {loading ? (
          <>
            <Skeleton variant="circular" width={40} height={40} />
            <Box>
              <Skeleton height={21} width={350} />
              <Skeleton height={21} width={350} />
            </Box>
            <Skeleton height={21} width={60} />
          </>
        ) : (
          <>
            <Box>
              {type === 'HELP_SERVICE' ? (
                <AvatarWrapperSecondary>
                  <AssistantIcon sx={{ fontSize: 25 }} />
                </AvatarWrapperSecondary>
              ) : (
                <AvatarWrapperError>
                  <ErrorOutlineIcon sx={{ fontSize: 25 }} />
                </AvatarWrapperError>
              )}
            </Box>
            <Box>
              {type === 'HELP_SERVICE' ? (
                <Typography variant="h5">{`${item.total} usuários receberam hipóteses diagnósticas graves e não contataram o serviço de ajuda`}</Typography>
              ) : (
                <Typography variant="h5">
                  {`${item.total}% do time ${item.setor} apresenta ${item.diagnostico} elevado`}
                </Typography>
              )}
              {type === 'HELP_SERVICE' ? (
                <Typography variant="subtitle1">
                  Sugerimos que comunique que os usuários da plataforma podem solicitar ajuda
                  gratuitamente e sempre que precisarem
                </Typography>
              ) : (
                <>
                  <Typography variant="subtitle1"> Média geral da empresa: 12%</Typography>
                  <Typography variant="subtitle1">Média HSPW: 10%</Typography>
                </>
              )}
            </Box>
            <Box>
              <Typography variant="subtitle1">15:30 </Typography>
            </Box>
          </>
        )}
      </Card>
    )
  }

  return (
    <Card sx={{ maxWidth: { sx: 400, sm: 600 } }}>
      <Header />
      <Divider />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          m: 2
        }}>
        <List
          sx={{
            maxHeight: !isExpand ? 300 : 'none',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}>
          {loading && (
            <>
              <CardItem type="" item={{ total: 0 }} />
              <CardItem type="" item={{ total: 0 }} />
              <CardItem type="" item={{ total: 0 }} />
            </>
          )}
          {diagnosticoSectorData?.map((item, index) => (
            <CardItem key={index} type="DIAGNOSTIC_SECTOR" item={item} />
          ))}
          {helpServiceData?.map((item, index) => (
            <CardItem key={index} type="HELP_SERVICE" item={item} />
          ))}
        </List>
      </Box>
    </Card>
  )
}
