import {
  Dialog,
  DialogTitle,
  Grid,
  Button,
  TextField,
  MenuItem,
  DialogContent,
  FormControl,
  DialogActions,
  Typography,
  Switch
} from '@mui/material'
import { useState, ChangeEvent, useContext } from 'react'
import { useQuery } from 'react-query'
import { companyService } from '../../../services/companyServices'
import { AuthContext } from '../../../contexts/auth/AuthContext'

type FilterModalProps = {
  open: boolean
  onClose: () => void
  onFilter: (filter: any) => void
  setFilters: (filter: any) => void
}

export const FilterModal = (props: FilterModalProps) => {
  const auth = useContext(AuthContext)
  const filterCache = JSON.parse(localStorage.getItem('helpRequestFilter') || '{}')

  const { open, onClose, onFilter, setFilters } = props
  const [empresa, setEmpresa] = useState(
    filterCache && filterCache.empresa ? filterCache.empresa : ''
  )
  const [status, setStatus] = useState(
    filterCache && filterCache.status ? filterCache.status : ['EM ATENDIMENTO', 'AGUARDANDO']
  )
  const [assunto, setAssunto] = useState(
    filterCache && filterCache.assunto ? filterCache.assunto : ''
  )
  const [clienteInterno, setClienteInterno] = useState(filterCache?.exibir_cliente_interno || false)

  const { data: companiesChild, isLoading: companyChildLoading } = useQuery(
    'companies',
    async () => {
      return await companyService.getAllCompany({})
    }
  )

  const handleFilter = () => {
    const filter = {
      ...(empresa && { empresa: empresa }),
      ...(status && { status: status }),
      ...(assunto && { assunto: assunto }),
      ...{ exibir_cliente_interno: clienteInterno }
    }
    localStorage.setItem('helpRequestFilter', JSON.stringify(filter))
    setFilters(filter)
    onFilter(filter)

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Definir Filtros</DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ paddingTop: 1 }}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Assunto"
                variant="outlined"
                fullWidth
                select
                onChange={(e) => setAssunto(e.target.value)}
                value={assunto}>
                <MenuItem value="">Nenhum</MenuItem>
                <MenuItem value="SAUDE_MENTAL">Saúde Mental</MenuItem>
                <MenuItem value="Mental">Mental</MenuItem>
                <MenuItem value="SAUDE_FISICA">Saúde Física</MenuItem>
                <MenuItem value="Nutricional">Nutricional</MenuItem>
                <MenuItem value="Bullying">Bullying</MenuItem>
                <MenuItem value="Dulce">Dulce</MenuItem>
                <MenuItem value="Outro">Outros</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Status"
                variant="outlined"
                fullWidth
                select
                SelectProps={{
                  multiple: true,
                  value: status,
                  onChange: (e: any) => setStatus(e.target.value)
                }}>
                <MenuItem value="EM ATENDIMENTO">Em atendimento</MenuItem>
                <MenuItem value="AGUARDANDO">Aguardando</MenuItem>
                <MenuItem value="CONCLUIDO">Concluído</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>

        {auth?.user && auth?.user.admin && (
          <Grid container spacing={1} sx={{ my: 1 }}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <TextField
                  label="Empresa"
                  variant="outlined"
                  fullWidth
                  select
                  onChange={(e) => setEmpresa(e.target.value)}
                  value={empresa}>
                  <MenuItem value="">Nenhum</MenuItem>

                  {companiesChild?.map((company: any) => (
                    <MenuItem key={company._id} value={company._id}>
                      {company.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sx={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                onChange={(e) => setClienteInterno(e.target.checked)}
                checked={clienteInterno}
              />
              <Typography
                variant="h6"
                sx={{ mt: 2, mb: 1.8, cursor: 'pointer' }}
                onClick={() => {
                  setClienteInterno(!clienteInterno)
                }}>
                Exibir clientes internos
              </Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button onClick={handleFilter} variant="contained" color="primary">
          Buscar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
