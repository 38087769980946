import { AppBar, Box, IconButton, Toolbar, Typography, useTheme } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import SearchIcon from '@mui/icons-material/Search'

export const Header = ({
  handleDrawerToggle,
  drawerWidth,
  appBarHeight
}: {
  handleDrawerToggle: () => void
  drawerWidth: number
  appBarHeight: number
}) => {
  const theme = useTheme()
  return (
    <AppBar
      position="fixed"
      elevation={9}
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
        backgroundColor: '#fff'
      }}>
      <Toolbar
        sx={{
          backgroundColor: `#fff`,
          boxShadow: '0px 0px 10px 0px rgba(0,0,0,0)',
          height: appBarHeight
        }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' }, color: `${theme.sidebar.background}` }}>
          <MenuIcon />
        </IconButton>
        {/* <IconButton
          color="primary"
          aria-label="search"
          edge="start"
          onClick={() => console.log('click')}
          sx={{
            ml: 'auto'
          }}>
          <SearchIcon />
        </IconButton> */}
      </Toolbar>
    </AppBar>
  )
}
