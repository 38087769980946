import api from './api'

export interface ICompanyCycleService {
  getAllCompanyCycles: (params: any) => Promise<any>
  getCycleUsersProgress: (id: string, company: string) => Promise<any>
}

export const companyCycleServices = <ICompanyCycleService>{
  getAllCompanyCycles: async (params: any) => {
    try {
      const { data } = await api.get('/auth/empresa-ciclos', { params })

      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar ciclos')
    }
  },

  getCycleUsersProgress: async (id: string, company: string) => {
    try {
      const { data } = await api.get(`/auth/empresa-ciclos/${id}/andamento-usuarios`, {
        params: {
          empresa: company
        }
      })

      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar progresso dos usuários')
    }
  }
}
