import { createContext } from 'react'
import { TUser } from '../../types/user'

export interface IAuthContext {
  user: TUser | null
  updateUser: (user: TUser) => void
  signIn: (email: string, password: string, token2FA: string) => Promise<boolean>
  signOut: () => void
  signed: boolean
}

export const AuthContext = createContext({} as IAuthContext)
