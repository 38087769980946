import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import PersonIcon from '@mui/icons-material/Person'
import Email from '@mui/icons-material/Email'
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { Avatar, Typography, useTheme } from '@mui/material'
import logo from '../../assets/login/logo.png'
import BarChartIcon from '@mui/icons-material/BarChart'
import { useEffect, useState } from 'react'
import LogoutIcon from '@mui/icons-material/Logout'
import { Header } from './Header'
import { AuthContext } from '../../contexts/auth/AuthContext'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'

import TwoFactorAuthenticationModal from '../../components/2FAModals/2FAConfigModal'

import tfaImg from '../../assets/home/2fa.png'

const drawerWidth = 240
const appBarHeight = 64

export function Sidebar() {
  const auth = useContext(AuthContext)
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [openTwoFactorModal, setOpenTwoFactorModal] = useState<boolean>(false)

  // const [open, setOpen] = React.useState(true)
  const navigate = useNavigate()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const drawer = (
    <Box
      sx={{
        backgroundColor: `${theme.sidebar.background}`,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <Box
        component={'img'}
        src={logo}
        alt="logo"
        width={115}
        sx={{ p: 1, alignSelf: 'flex-start' }}
      />
      <Divider sx={{ backgroundColor: '#fff', opacity: 0.1, width: '90%' }} />
      <Avatar
        sx={{
          width: 60,
          height: 60,
          m: 1,
          bgcolor: 'primary.main',
          mt: theme.spacing(3),
          cursor: 'pointer'
        }}
        src={auth.user?.avatar}
        onClick={() => {
          navigate('/personal-data')
          setMobileOpen(false)
        }}
      />
      <Typography variant="h6" sx={{ color: '#fff', opacity: 0.7 }}>
        {auth.user?.nickname}
      </Typography>
      <Typography variant="body2" sx={{ color: '#fff', opacity: 0.7 }}>
        {auth.user?.nome_empresa_setor}
      </Typography>
      <Divider sx={{ backgroundColor: '#fff', opacity: 0.1, width: '90%', mt: theme.spacing(3) }} />

      <List sx={{ width: '90%' }}>
        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            // hover states
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              opacity: 0.9,
              '&, & .MuiListItemIcon-root': {
                opacity: 0.8
              }
            }
          }}>
          <ListItemButton
            selected={useLocation().pathname === '/home'}
            onClick={() => {
              navigate('/home')
              setMobileOpen(false)
            }}>
            <ListItemIcon sx={{ color: '#fff', opacity: 0.7 }}>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Inicio" sx={{ color: '#fff', opacity: 0.7 }} />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            // hover states
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              opacity: 0.9,
              '&, & .MuiListItemIcon-root': {
                opacity: 0.8
              }
            }
          }}>
          <ListItemButton
            selected={useLocation().pathname === '/reports'}
            onClick={() => {
              navigate('/reports')
              setMobileOpen(false)
            }}>
            <ListItemIcon sx={{ color: '#fff', opacity: 0.7 }}>
              <FormatAlignLeftIcon />
            </ListItemIcon>
            <ListItemText primary="Relatórios" sx={{ color: '#fff', opacity: 0.7 }} />
          </ListItemButton>
        </ListItem>
        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            // hover states
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              opacity: 0.9,
              '&, & .MuiListItemIcon-root': {
                opacity: 0.8
              }
            }
          }}>
          <ListItemButton
            selected={useLocation().pathname === '/company-registration'}
            onClick={() => {
              navigate('/company-registration')
              setMobileOpen(false)
            }}>
            <ListItemIcon sx={{ color: '#fff', opacity: 0.7 }}>
              <InsertDriveFileIcon />
            </ListItemIcon>
            <ListItemText primary="Cadastros" sx={{ color: '#fff', opacity: 0.7 }} />
          </ListItemButton>
        </ListItem>

        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            // hover states
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              opacity: 0.9,
              '&, & .MuiListItemIcon-root': {
                opacity: 0.8
              }
            }
          }}>
          <ListItemButton
            selected={useLocation().pathname === '/users'}
            onClick={() => {
              navigate('/users')
              setMobileOpen(false)
            }}>
            <ListItemIcon sx={{ color: '#fff', opacity: 0.7 }}>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Usuários" sx={{ color: '#fff', opacity: 0.7 }} />
          </ListItemButton>
        </ListItem>

        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            // hover states
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              opacity: 0.9,
              '&, & .MuiListItemIcon-root': {
                opacity: 0.8
              }
            }
          }}>
          <ListItemButton
            selected={useLocation().pathname === '/help-requests'}
            onClick={() => {
              navigate('/help-requests')
              setMobileOpen(false)
            }}>
            <ListItemIcon sx={{ color: '#fff', opacity: 0.7 }}>
              <Email />
            </ListItemIcon>
            <ListItemText primary="Pedidos de ajuda" sx={{ color: '#fff', opacity: 0.7 }} />
          </ListItemButton>
        </ListItem>

        <ListItem
          sx={{
            padding: 0,
            margin: 0,
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'rgba(255, 255, 255, 0.08)',
              opacity: 0.9,
              '&, & .MuiListItemIcon-root': {
                opacity: 0.8
              }
            }
          }}>
          <ListItemButton
            onClick={() => {
              auth.signOut()
              navigate('/')
              setMobileOpen(false)
            }}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: '#fff', opacity: 0.7 }} />
            </ListItemIcon>
            <ListItemText primary="Sair" sx={{ color: '#fff', opacity: 0.7 }} />
          </ListItemButton>
        </ListItem>
      </List>

      <Divider />
    </Box>
  )

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <TwoFactorAuthenticationModal
        open={openTwoFactorModal}
        handleClose={() => setOpenTwoFactorModal(false)}
      />
      <Header
        handleDrawerToggle={handleDrawerToggle}
        drawerWidth={drawerWidth}
        appBarHeight={appBarHeight}
      />
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          elevation={9}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth
            }
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          elevation={9}
          color="secondary"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 'none'
            }
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mt: `${appBarHeight}px`
        }}>
        {auth.user &&
        (auth.user['2FA']?.ativo === false || auth.user['2FA']?.ativo === undefined) ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Box
              component={'img'}
              src={tfaImg}
              alt="2FA"
              width={150}
              sx={{ width: 150, height: 100 }}
            />
            <Typography>
              Para garantir a segurança de todos por aqui, algumas partes do sistema só podem ser
              acessadas com a autenticação de dois fatores habilitada{' '}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
              <Button
                variant="contained"
                sx={{ backgroundColor: '#9C27B0' }}
                onClick={() => setOpenTwoFactorModal(true)}>
                Habilitar autenticação de dois fatores
              </Button>
            </Box>
          </Box>
        ) : (
          <Outlet />
        )}
      </Box>
    </Box>
  )
}
