import { Login } from '../pages/Login'
import { Routes, Route } from 'react-router-dom'
import { ForgotPassword } from '../pages/ForgotPassword'
import { RecoverPassword } from '../pages/RecoverPassword'

export const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/recover-password" element={<RecoverPassword />} />
    </Routes>
  )
}
