import api from './api'

export interface ICompanyBranchService {
  getAllCompanyBranch: (params: any) => Promise<any>
  putCompanyBranch: (params: any) => Promise<any>
  postCompanyBranch: (params: any) => Promise<any>
}

export const companyBranchServices = <ICompanyBranchService>{
  getAllCompanyBranch: async (params: any) => {
    try {
      const { data } = await api.get('/auth/empresa-filiais', { params })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar filiais')
    }
  },
  putCompanyBranch: async (params: any) => {
    try {
      const { data } = await api.put(`/auth/empresa-filiais/${params.id}`, params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao atualizar filial')
    }
  },
  postCompanyBranch: async (params: any) => {
    try {
      const { data } = await api.post('/auth/empresa-filiais', params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao cadastrar filial')
    }
  }
}
