import { Box, Typography, Breadcrumbs, Grid, TextField, MenuItem, Button } from '@mui/material'
import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import AddIcon from '@mui/icons-material/Add'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import { helpRequestsServices } from '../../../services/helpRequestsServices'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'

export const HelpRequestEdit = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const { data } = location.state || { data: null }

  const schema = z.object({
    codigo: z.string().min(1, 'Campo obrigatório'),
    usuario: z.string().min(1, 'Campo obrigatório'),
    empresa: z.string().min(1, 'Campo obrigatório'),
    contato_preferencial: z.string().optional(),
    email: z.string().optional(),
    telefone: z.string().optional(),
    ja_pediu_ajuda: z.boolean(),

    assunto: z.string().min(1, 'Campo obrigatório'),
    tipo_origem: z.string().min(1, 'Campo obrigatório'),
    data_criacao: z.string().min(1, 'Campo obrigatório'),
    diagnostico_titulo: z.string().optional(),
    diagnostico_palavra: z.string().optional(),
    descricao: z.string().optional(),

    status: z.string().min(1, 'Campo obrigatório'),
    data_atendimento: z.string().min(1, 'Campo obrigatório'),
    encaminhado: z.boolean().optional(),
    reclassificacao_assunto: z.boolean().optional(),

    data_acompanhamento: z.string().optional(),
    tipo: z.string().optional(),
    descricao_acompanhamento: z.string().optional(),

    data_contato: z.string().optional(),
    meio: z.string().optional(),
    descricao_contato: z.string().optional(),

    status_atendimento_empresa_parceira: z.string().optional(),

    data_atendimento_empresa: z.string().optional(),
    tipo_profissional: z.string().optional(),
    profissional_encaminhado: z.boolean().optional(),
    link_atendimento: z.string().optional(),
    compareceu: z.boolean(),

    feedback: z.boolean().optional(),
    nome: z.string().min(1, 'Campo obrigatório'),
    senha: z.string().min(8, 'Campo obrigatório'),
    empresa_filha: z.string().optional(),
    cliente: z.string().min(1, 'Campo obrigatório')
  })

  type Schema = z.infer<typeof schema>

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  let formData = data || {
    codigo: '',
    usuario: '',
    empresa: '',
    contato_preferencial: '',
    email: '',
    telefone: '',
    ja_pediu_ajuda: '',

    assunto: '',
    tipo_origem: '',
    data_criacao: '',
    diagnostico_titulo: '',
    diagnostico_palavra: '',
    descricao: '',

    status: '',
    data_atendimento: '',
    encaminhado: '',
    reclassificacao_assunto: '',

    data_acompanhamento: '',
    tipo: '',
    descricao_acompanhamento: '',

    data_contato: '',
    meio: '',
    descricao_contato: '',

    status_atendimento_empresa_parceira: '',

    data_atendimento_empresa: '',
    tipo_profissional: '',
    profissional_encaminhado: '',
    link_atendimento: '',
    compareceu: '',

    feedback: '',
    nome: '',
    senha: '',
    empresa_filha: '',
    cliente: ''
  }

  const [contatos, setContatos] = useState<Array<any>>([])
  const [acompanhamentos, setAcompanhamentos] = useState<Array<any>>([])
  const [atendimentos, setAtendimentos] = useState<Array<any>>([])

  const { data: helpRequestData, isLoading: helpRequestDataLoading } = useQuery(
    'helpRequest',
    async () => {
      return await helpRequestsServices.getOneHelpRequests(data?._id, {})
    }
  )
  const { data: helpRequestAttendanceData, isLoading: helpRequestAttendanceDataLoading } = useQuery(
    'helpRequestAttendance',
    async () => {
      return await helpRequestsServices.getAllHelpRequestsAttendance(data?._id)
    }
  )
  const { data: helpRequestContactData, isLoading: helpRequestContactDataLoading } = useQuery(
    'helpRequestContact',
    async () => {
      return await helpRequestsServices.getAllHelpRequestsContact(data?._id)
    }
  )
  const { data: helpRequestMonitoringData, isLoading: helpRequestMonitoringDataLoading } = useQuery(
    'helpRequestMonitoring',
    async () => {
      return await helpRequestsServices.getAllHelpRequestsMonitoring(data?._id)
    }
  )

  const mutationPutHelpRequest = useMutation(
    async () => {
      const dataUpdate = {}

      return await helpRequestsServices
        .putHelpRequest(data?._id, {
          ...dataUpdate
        })
        .then(() => {
          queryClient.invalidateQueries('helpRequest')
        })
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Pedido de ajuda atualizado com sucesso!', { variant: 'success' })
      },
      onError: () => {
        console.log('error')
        enqueueSnackbar('Erro ao atualizar pedido de ajuda!', { variant: 'error' })
      }
    }
  )

  useEffect(() => {
    !helpRequestContactDataLoading && setContatos(helpRequestContactData)
    !helpRequestMonitoringDataLoading && setAcompanhamentos(helpRequestMonitoringData)
    !helpRequestAttendanceDataLoading && setAtendimentos(helpRequestAttendanceData)
  }, [
    helpRequestContactDataLoading,
    helpRequestMonitoringDataLoading,
    helpRequestAttendanceDataLoading
  ])

  const handleAddContato = () => {
    setContatos((contatos) => [
      ...contatos,
      {
        data_contato: '',
        meio: '',
        descricao: ''
      }
    ])
  }

  const _renderOrigemPedidoAjuda = (pedido: string) => {
    if (pedido === 'PANICO') return 'Botão de Pânico'
    if (pedido === 'FELICITOMETRO') return 'Felicitômetro'
    if (pedido === 'DIAGNOSTICO') return 'Card de Diagnóstico'
  }

  const onSubmit = (data: Schema) => {
    const payloadPut = {
      ...data
    }

    // mutationPutHelpRequest.mutate(payloadPut)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2} onSubmit={handleSubmit(onSubmit)}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          color="inherit"
          onClick={() => {
            navigate('/')
          }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          color="inherit"
          onClick={() => {
            navigate('/help-requests')
          }}>
          Pedidos de ajuda
        </Link>
        <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
          Editar pedido de ajuda
        </Typography>
      </Breadcrumbs>
      <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
        Dados do colaborador
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Código"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.codigo || ''}
            error={!!errors.codigo}
            helperText={errors.codigo?.message}
            key={helpRequestData?.codigo}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Usuário"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.usuario?.username || ''}
            error={!!errors.usuario}
            helperText={errors.usuario?.message}
            key={helpRequestData?.usuario?.username}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4} sm={8}>
          <TextField
            label="Empresa"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.empresa || ''}
            error={!!errors.empresa}
            helperText={errors.empresa?.message}
            key={helpRequestData?.empresa}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Contato preferencial"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.contato_preferencial || ''}
            error={!!errors.contato_preferencial}
            helperText={errors.contato_preferencial?.message}
            key={helpRequestData?.contato_preferencial}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="E-mail"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.email || ''}
            error={!!errors.email}
            helperText={errors.email?.message}
            key={helpRequestData?.email}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Telefone"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.telefone || ''}
            error={!!errors.telefone}
            helperText={errors.telefone?.message}
            key={helpRequestData?.telefone}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Já pediu ajuda"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.ja_pediu_ajuda ? 'Sim' : 'Não' || ''}
            error={!!errors.ja_pediu_ajuda}
            helperText={errors.ja_pediu_ajuda?.message}
            key={helpRequestData?.ja_pediu_ajuda}
            disabled
          />
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
        Dados do pedido
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Assunto"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.assunto || ''}
            error={!!errors.assunto}
            helperText={errors.assunto?.message}
            key={helpRequestData?.assunto}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Origem do Pedido"
            variant="outlined"
            fullWidth
            defaultValue={_renderOrigemPedidoAjuda(helpRequestData?.tipo_origem) || ''}
            error={!!errors.tipo_origem}
            helperText={errors.tipo_origem?.message}
            key={helpRequestData?.tipo_origem}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4} sm={8}>
          <TextField
            label="Data do Pedido"
            variant="outlined"
            fullWidth
            defaultValue={moment(helpRequestData?.data_criacao).format('DD/MM/YYYY HH:mm:ss') || ''}
            error={!!errors.data_criacao}
            helperText={errors.data_criacao?.message}
            key={helpRequestData?.data_criacao}
            disabled
          />
        </Grid>
        {helpRequestData?.tipo_origem === 'DIAGNOSTICO' && (
          <>
            <Grid item xs={12} md={4}>
              <TextField
                label="Diagnóstico"
                variant="outlined"
                fullWidth
                defaultValue={helpRequestData?.diagnostico_titulo || ''}
                error={!!errors.diagnostico_titulo}
                helperText={errors.diagnostico_titulo?.message}
                key={helpRequestData?.diagnostico_titulo}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Grau do Diagnóstico"
                variant="outlined"
                fullWidth
                defaultValue={helpRequestData?.diagnostico_palavra || ''}
                error={!!errors.diagnostico_palavra}
                helperText={errors.diagnostico_palavra?.message}
                key={helpRequestData?.diagnostico_palavra}
                disabled
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <TextField
            label="Descrição do Pedido"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.descricao || ''}
            error={!!errors.descricao}
            helperText={errors.descricao?.message}
            key={helpRequestData?.descricao}
            disabled
          />
        </Grid>
      </Grid>

      <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
        Atendimento
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Status"
            variant="outlined"
            fullWidth
            select
            defaultValue={helpRequestData?.status}
            {...register('status')}
            error={!!errors.status}
            helperText={errors.status?.message}>
            <MenuItem value="EM ATENDIMENTO">Em atendimento</MenuItem>
            <MenuItem value="AGUARDANDO">Aguardando</MenuItem>
            <MenuItem value="CONCLUIDO">Concluido</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Início atendimento"
            variant="outlined"
            fullWidth
            defaultValue={helpRequestData?.data_atendimento || ''}
            {...register('data_atendimento')}
            error={!!errors.data_atendimento}
            helperText={errors.data_atendimento?.message}
            key={helpRequestData?.data_atendimento}
          />
        </Grid>
        <Grid item xs={12} md={4} sm={8}>
          <TextField
            label="Encaminhado"
            variant="outlined"
            fullWidth
            select
            defaultValue={helpRequestData?.encaminhado || ''}
            {...register('encaminhado')}
            error={!!errors.encaminhado}
            helperText={errors.encaminhado?.message}>
            <MenuItem value="true">Sim</MenuItem>
            <MenuItem value="false">Não</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            label="Reclassificação assunto"
            variant="outlined"
            fullWidth
            select
            defaultValue={helpRequestData?.reclassificacao_assunto || ''}
            error={!!errors.reclassificacao_assunto}
            helperText={errors.reclassificacao_assunto?.message}>
            <MenuItem value="PSICOLOGIA">Psicologia</MenuItem>
            <MenuItem value="SAUDE_MENTAL">Saúde mental</MenuItem>
            <MenuItem value="NUTRICIONAL">Nutricional</MenuItem>
            <MenuItem value="SAUDE_FISICA">Saúde Física</MenuItem>
            <MenuItem value="OUTROS">Outros</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {/* acompanhamentos */}
      {!helpRequestDataLoading &&
        acompanhamentos.map((acompanhamento: any, index: any) => {
          return (
            <>
              <Typography variant="h6" sx={{ mb: 0.5, mt: 2 }}>
                {index + 1}° Acompanhamento
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Data e hora"
                    variant="outlined"
                    fullWidth
                    defaultValue={moment(acompanhamento?.data_acompanhamento).format(
                      'DD/MM/YYYY HH:mm:ss'
                    )}
                    {...register('data_acompanhamento')}
                    error={!!errors.data_acompanhamento}
                    helperText={errors.data_acompanhamento?.message}
                    key={acompanhamento?.data_acompanhamento}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Tipo"
                    variant="outlined"
                    fullWidth
                    select
                    defaultValue={acompanhamento?.tipo}
                    error={!!errors.tipo}
                    helperText={errors.tipo?.message}>
                    <MenuItem value="AVALIACAO_INICIAL">Avaliação inicial</MenuItem>
                    <MenuItem value="RECOMENDACAO_POS_CONTATO">Recomendação pós contato</MenuItem>
                    <MenuItem value="ACOMPANHAMENTO">Acompanhamento</MenuItem>
                    <MenuItem value="FINALIZACAO_ATENDIMENTO">Finalização do atendimento</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    label="Descrição"
                    variant="outlined"
                    fullWidth
                    defaultValue={acompanhamento?.descricao}
                    error={!!errors.descricao_acompanhamento}
                    helperText={errors.descricao_acompanhamento?.message}
                    key={acompanhamento?.descricao_acompanhamento}
                  />
                </Grid>
              </Grid>
            </>
          )
        })}

      {/* contatos */}
      {!helpRequestContactDataLoading &&
        helpRequestContactData.map((contato: any, index: any) => {
          return (
            <>
              <Typography variant="h6" sx={{ mb: 0.5, mt: 2 }}>
                {index + 1}° Contato
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Data e hora"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      moment(contato?.data_acompanhamento).format('DD/MM/YYYY HH:mm:ss') || ''
                    }
                    {...register('data_acompanhamento')}
                    error={!!errors.data_acompanhamento}
                    helperText={errors.data_acompanhamento?.message}
                    key={contato?.data_acompanhamento}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Contato"
                    variant="outlined"
                    fullWidth
                    select
                    defaultValue={contato?.meio || ''}
                    {...register('status')}
                    error={!!errors.status}
                    helperText={errors.status?.message}>
                    <MenuItem value="LIGACAO">Ligação</MenuItem>
                    <MenuItem value="EMAIL">E-mail</MenuItem>
                    <MenuItem value="WHATSAPP">Whatsapp</MenuItem>
                    <MenuItem value="PRESENCIAL">Presencial</MenuItem>
                    <MenuItem value="CHAT_HSPW">Chat HSPW</MenuItem>
                    <MenuItem value="OUTROS">Outros</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    label="Descrição"
                    variant="outlined"
                    fullWidth
                    defaultValue={contato?.descricao || ''}
                    {...register('descricao_contato')}
                    error={!!errors.descricao_contato}
                    helperText={errors.descricao_contato?.message}
                    key={contato?.descricao_contato}
                  />
                </Grid>
              </Grid>
            </>
          )
        })}

      <Grid item xs={12} md={12} sx={{ textAlign: 'left', mt: 1 }}>
        <Button sx={{ px: 5, mr: 5, mt: 1 }} startIcon={<AddIcon />} variant="outlined">
          Adicionar acompanhamento
        </Button>
        <Button
          sx={{ px: 5, mt: 1 }}
          startIcon={<AddIcon />}
          variant="outlined"
          onClick={() => handleAddContato()}>
          Adicionar contato
        </Button>
      </Grid>

      <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
        Atendimento empresa parceira
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            label="Status"
            variant="outlined"
            fullWidth
            select
            defaultValue={helpRequestData?.status_atendimento_empresa_parceira}
            {...register('status_atendimento_empresa_parceira')}
            error={!!errors.status_atendimento_empresa_parceira}
            helperText={errors.status_atendimento_empresa_parceira?.message}>
            <MenuItem value="EM ATENDIMENTO">Em atendimento</MenuItem>
            <MenuItem value="AGUARDANDO">Aguardando</MenuItem>
            <MenuItem value="CONCLUIDO">Concluido</MenuItem>
          </TextField>
        </Grid>
      </Grid>

      {/* atendimentos */}
      {!helpRequestAttendanceDataLoading &&
        atendimentos.map((atendimento: any, index: any) => {
          return (
            <>
              <Typography variant="h6" sx={{ mb: 0.5, mt: 2 }}>
                {index + 1}º Atendimento
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Data e hora"
                    variant="outlined"
                    fullWidth
                    defaultValue={
                      moment(atendimento?.data_atendimento).format('DD/MM/YYYY HH:mm:ss') || ''
                    }
                    {...register('data_atendimento_empresa')}
                    error={!!errors.data_atendimento_empresa}
                    helperText={errors.data_atendimento_empresa?.message}
                    key={1}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Tipo profissional"
                    variant="outlined"
                    fullWidth
                    defaultValue={atendimento?.tipo_profissional || ''}
                    {...register('tipo_profissional')}
                    error={!!errors.tipo_profissional}
                    helperText={errors.tipo_profissional?.message}
                    key={atendimento?.tipo_profissional}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Profissional encaminhado"
                    variant="outlined"
                    fullWidth
                    defaultValue={atendimento?.profissional_encaminhado || ''}
                    {...register('profissional_encaminhado')}
                    error={!!errors.profissional_encaminhado}
                    helperText={errors.profissional_encaminhado?.message}
                    key={atendimento?.profissional_encaminhado}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Link do atendimento"
                    variant="outlined"
                    fullWidth
                    defaultValue={atendimento?.link_atendimento || ''}
                    {...register('link_atendimento')}
                    error={!!errors.link_atendimento}
                    helperText={errors.link_atendimento?.message}
                    key={atendimento?.link_atendimento}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Compareceu"
                    variant="outlined"
                    fullWidth
                    select
                    defaultValue={atendimento?.compareceu || ''}
                    {...register('compareceu')}
                    error={!!errors.compareceu}
                    helperText={errors.compareceu?.message}>
                    <MenuItem value="true">Sim</MenuItem>
                    <MenuItem value="false">Não</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    label="Feedback"
                    variant="outlined"
                    fullWidth
                    defaultValue={atendimento?.feedback || ''}
                    {...register('feedback')}
                    error={!!errors.feedback}
                    helperText={errors.feedback?.message}
                    key={atendimento?.feedback}
                  />
                </Grid>
              </Grid>
            </>
          )
        })}

      <Grid item xs={12} md={12} sx={{ textAlign: 'left', mt: 2 }}>
        <Button sx={{ px: 5 }} startIcon={<AddIcon />} variant="outlined">
          Adicionar atendimento
        </Button>
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'center', mt: 2 }}>
        <Button sx={{ px: 5 }} variant="contained" type="submit">
          Salvar
        </Button>
      </Grid>
    </Box>
  )
}
