import {
  Box,
  Typography,
  Button,
  useTheme,
  IconButton,
  TextField,
  Avatar,
  Modal,
  CardHeader,
  Card,
  Divider,
  Switch,
  Grid,
  InputAdornment,
  CircularProgress
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import InputMask, { ReactInputMask } from 'react-input-mask'
import userServices from '../../services/userServices'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import ModeEdit from '@mui/icons-material/ModeEdit'
import { useState, useContext, ChangeEvent, forwardRef } from 'react'
import { UploadModal } from './UploadModal'
import { AuthContext } from '../../contexts/auth/AuthContext'
import TwoFactorAuthenticationModal from '../../components/2FAModals/2FAConfigModal'

const useStyles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textField: {
    margin: '10px 0'
  }
})

const PhoneMaskCustom = forwardRef<HTMLElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <InputMask
      {...other}
      mask="(99) 99999-9999"
      maskChar={null}
      inputRef={ref}
      onChange={onChange}
    />
  )
})

export const PersonalData = () => {
  const theme = useTheme()
  const auth = useContext(AuthContext)
  const [openModal, setOpenModal] = useState(false)
  const [editPersonalDataMode, setEditPersonalDataMode] = useState(false)
  const [editContactDataMode, setEditContactDataMode] = useState(false)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [activeTwoFactor, setActiveTwoFactor] = useState(auth?.user?.['2FA']?.ativo || false)
  const [editPersonalData, setEditPersonalData] = useState({
    username: auth.user?.username || '',
    data_nascimento: auth.user?.data_nascimento || '',
    nickname: auth.user?.nickname || '',
    cidade: auth.user?.cidade || '',
    uf: auth.user?.uf || ''
  })
  const [editContactData, setEditContactData] = useState({
    email: auth.user?.email || '',
    telefone: auth.user?.telefone || ''
  })
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [openTwoFactorModal, setOpenTwoFactorModal] = useState(false)
  const [personalDataLoading, setPersonalDataLoading] = useState(false)
  const [contactDataLoading, setContactDataLoading] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)

  const classes = useStyles()

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleUpload = async (file: File) => {
    try {
      setSelectedFile(file)

      const user = auth.user

      if (!user) return

      const reader = new FileReader()
      reader.readAsDataURL(file)

      const onLoadPromise = new Promise((resolve, reject) => {
        reader.onload = resolve
        reader.onerror = reject
      })

      await onLoadPromise

      const base64 = reader.result as string

      await auth.updateUser({
        ...user,
        avatar: base64
      })
    } catch (error) {
      console.log(error)
    }
  }

  const cancelPersonalDataEditMode = () => {
    setEditPersonalDataMode(false)
    setEditPersonalData({
      username: auth.user?.username || '',
      data_nascimento: auth.user?.data_nascimento || '',
      nickname: auth.user?.nickname || '',
      cidade: auth.user?.cidade || '',
      uf: auth.user?.uf || ''
    })
  }

  const cancelContactDataEditMode = () => {
    setEditContactDataMode(false)
    setEditContactData({
      email: auth.user?.email || '',
      telefone: auth.user?.telefone || ''
    })
  }

  const handleSavePersonalData = async () => {
    try {
      setPersonalDataLoading(true)
      const user = auth.user

      if (!user) return

      await auth.updateUser({
        ...user,
        username: editPersonalData.username,
        data_nascimento: editPersonalData.data_nascimento,
        nickname: editPersonalData.nickname,
        cidade: editPersonalData.cidade,
        uf: editPersonalData.uf
      })

      setEditPersonalDataMode(false)
    } catch (error) {
      console.log(error)
    } finally {
      setPersonalDataLoading(false)
    }
  }

  const handleSaveContactData = async () => {
    try {
      setContactDataLoading(true)
      const user = auth.user

      if (!user) return

      await auth.updateUser({
        ...user,
        email: editContactData.email,
        telefone: editContactData.telefone
      })

      setEditContactDataMode(false)
    } catch (error) {
      console.log(error)
    } finally {
      setContactDataLoading(false)
    }
  }

  const cardHeaderActions = (
    editMode: boolean,
    setEditMode: any,
    cancelEditMode: any,
    handleSaveData: any,
    loading: boolean
  ) => {
    if (!editMode) {
      return (
        <IconButton
          onClick={() => setEditMode(true)}
          sx={{
            borderColor: theme.palette.primary.main,
            borderRadius: 1,
            height: 30
          }}>
          <ModeEdit fontSize="small" color="primary" />
          <Typography sx={{ color: theme.palette.primary.main, fontSize: 16 }}>Editar</Typography>
        </IconButton>
      )
    }

    return (
      <Box>
        <Button
          onClick={cancelEditMode}
          variant="outlined"
          color="error"
          size="small"
          sx={{
            mr: 1
          }}
          disabled={loading}>
          Cancelar
        </Button>
        <Button
          variant="outlined"
          color="success"
          size="small"
          onClick={handleSaveData}
          disabled={loading}>
          {loading ? <CircularProgress size={20} /> : 'Salvar'}
        </Button>
      </Box>
    )
  }

  const handleEditPersonalData = (e: ChangeEvent<HTMLInputElement>) => {
    setEditPersonalData({
      ...editPersonalData,
      [e.target.name]: e.target.value
    })
  }

  const handleEditContactData = (e: ChangeEvent<HTMLInputElement>) => {
    setEditContactData({
      ...editContactData,
      [e.target.name]: e.target.value
    })
  }

  const handleTwoFactorChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setActiveTwoFactor(!!e.target.checked)
    if (e.target.checked) {
      setOpenTwoFactorModal(true)
    }
  }

  const handleNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPassword(e.target.value)
  }

  const handleConfirmNewPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmNewPassword(e.target.value)
  }

  const handleSaveNewPassword = async () => {
    try {
      setPasswordLoading(true)

      if (newPassword !== confirmNewPassword) {
        throw new Error('Senhas não coincidem')
      }

      const user = auth.user

      if (!user) return

      await userServices.updatePassword(user.id || '', newPassword)

      setNewPassword('')
      setConfirmNewPassword('')
    } catch (error) {
      console.log(error)
    } finally {
      setPasswordLoading(false)
    }
  }

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword)
  }

  const handleShowConfirmPasswordClick = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const handleCloseTwoFactorModal = () => {
    setOpenTwoFactorModal(false)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={6}>
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
        <Typography variant="h3">Dados pessoais</Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" gutterBottom>
          Foto do perfil
        </Typography>
        <Box sx={{ width: 150, height: 150, display: 'flex', marginTop: 2 }}>
          <Avatar sx={{ width: 144, height: 144 }} src={auth.user?.avatar || undefined} />
          <IconButton
            sx={{ display: 'flex', justifySelf: 'flex-end', alignSelf: 'flex-end' }}
            onClick={handleOpenModal}>
            <PhotoCamera />
          </IconButton>
        </Box>
        <UploadModal open={openModal} onClose={handleCloseModal} onUpload={handleUpload} />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Card>
          <CardHeader
            title="Informações pessoais"
            action={cardHeaderActions(
              editPersonalDataMode,
              setEditPersonalDataMode,
              cancelPersonalDataEditMode,
              handleSavePersonalData,
              personalDataLoading
            )}
          />
          <Divider />
          <Box sx={{ py: 4, px: 6 }}>
            <Box
              sx={
                editPersonalDataMode
                  ? { display: 'flex', alignItems: 'center', marginBottom: 2 }
                  : { display: 'flex', alignItems: 'center' }
              }>
              <Typography variant="subtitle1" gutterBottom>
                <b>Usuário: </b>
                {!editPersonalDataMode && auth.user?.username}
              </Typography>
              {editPersonalDataMode && (
                <TextField
                  sx={{ ml: 2 }}
                  hiddenLabel
                  variant="filled"
                  defaultValue={auth.user?.username}
                  name="username"
                  onChange={handleEditPersonalData}
                  size="small"
                />
              )}
            </Box>
            <Box
              sx={
                editPersonalDataMode
                  ? { display: 'flex', alignItems: 'center', marginBottom: 2 }
                  : { display: 'flex', alignItems: 'center' }
              }>
              <Typography variant="subtitle1" gutterBottom>
                <b>Nome: </b>
                {!editPersonalDataMode && auth.user?.nickname}
              </Typography>
              {editPersonalDataMode && (
                <TextField
                  sx={{ ml: 2 }}
                  hiddenLabel
                  variant="filled"
                  defaultValue={auth.user?.nickname}
                  name="nickname"
                  inputProps={{ maxLength: 20 }}
                  onChange={handleEditPersonalData}
                  size="small"
                />
              )}
            </Box>
            <Box
              sx={
                editPersonalDataMode
                  ? { display: 'flex', alignItems: 'center', marginBottom: 2 }
                  : { display: 'flex', alignItems: 'center' }
              }>
              <Typography variant="subtitle1" gutterBottom>
                <b>Data de nascimento: </b>
                {!editPersonalDataMode && auth.user?.data_nascimento}
              </Typography>
              {editPersonalDataMode && (
                <TextField
                  sx={{ ml: 2 }}
                  hiddenLabel
                  variant="filled"
                  defaultValue={auth.user?.data_nascimento}
                  name="data_nascimento"
                  onChange={handleEditPersonalData}
                  size="small"
                />
              )}
            </Box>
            <Box
              sx={
                editPersonalDataMode
                  ? { display: 'flex', alignItems: 'center', marginBottom: 2 }
                  : { display: 'flex', alignItems: 'center' }
              }>
              <Typography variant="subtitle1" gutterBottom>
                <b>Estado: </b>
                {!editPersonalDataMode && auth.user?.uf}
              </Typography>
              {editPersonalDataMode && (
                <TextField
                  sx={{ ml: 2 }}
                  hiddenLabel
                  variant="filled"
                  defaultValue={auth.user?.uf}
                  name="uf"
                  onChange={handleEditPersonalData}
                  size="small"
                  inputProps={{ maxLength: 50 }}
                />
              )}
            </Box>
            <Box
              sx={
                editPersonalDataMode
                  ? { display: 'flex', alignItems: 'center', marginBottom: 2 }
                  : { display: 'flex', alignItems: 'center' }
              }>
              <Typography variant="subtitle1" gutterBottom>
                <b>Cidade atual: </b>
                {!editPersonalDataMode && auth.user?.cidade}
              </Typography>
              {editPersonalDataMode && (
                <TextField
                  sx={{ ml: 2 }}
                  hiddenLabel
                  variant="filled"
                  defaultValue={auth.user?.cidade}
                  name="cidade"
                  onChange={handleEditPersonalData}
                  size="small"
                  inputProps={{ maxLength: 100 }}
                />
              )}
            </Box>
          </Box>
        </Card>
        <Card>
          <CardHeader
            title="Informações de contato"
            action={cardHeaderActions(
              editContactDataMode,
              setEditContactDataMode,
              cancelContactDataEditMode,
              handleSaveContactData,
              contactDataLoading
            )}
          />
          <Divider />
          <Box sx={{ py: 4, px: 6 }}>
            <Box
              sx={
                editContactDataMode
                  ? { display: 'flex', alignItems: 'center', marginBottom: 2 }
                  : { display: 'flex', alignItems: 'center' }
              }>
              <Typography variant="subtitle1" gutterBottom>
                <b>Email: </b>
                {!editContactDataMode && auth.user?.email}
              </Typography>
              {editContactDataMode && (
                <TextField
                  sx={{ ml: 2 }}
                  hiddenLabel
                  variant="filled"
                  defaultValue={auth.user?.email}
                  name="email"
                  onChange={handleEditContactData}
                  size="small"
                />
              )}
            </Box>
            <Box
              sx={
                editContactDataMode
                  ? { display: 'flex', alignItems: 'center', marginBottom: 2 }
                  : { display: 'flex', alignItems: 'center' }
              }>
              <Typography variant="subtitle1" gutterBottom>
                <b>Número de celular: </b>
                {!editContactDataMode && auth.user?.telefone}
              </Typography>
              {editContactDataMode && (
                <TextField
                  sx={{ ml: 2 }}
                  hiddenLabel
                  variant="filled"
                  defaultValue={auth.user?.telefone}
                  name="telefone"
                  InputProps={{
                    inputComponent: PhoneMaskCustom
                  }}
                  onChange={handleEditContactData}
                  size="small"
                />
              )}
            </Box>
          </Box>
        </Card>
        <Card>
          <CardHeader
            title="Autenticação de dois fatores"
            action={
              <Switch
                checked={activeTwoFactor}
                onChange={handleTwoFactorChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          />
          <Divider />
          <Box sx={{ py: 3, px: 2 }}>
            <Typography variant="h5" sx={{ pb: 3 }}>
              Alterar senha
            </Typography>
            <Grid container spacing={3}>
              <Grid item md={5} xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  className={classes.textField}
                  type={showPassword ? 'text' : 'password'}
                  label="Nova senha"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton tabIndex={-1} onClick={handleShowPasswordClick}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <TextField
                  fullWidth
                  variant="filled"
                  className={classes.textField}
                  type={showConfirmPassword ? 'text' : 'password'}
                  label="Confirmar senha"
                  value={confirmNewPassword}
                  onChange={handleConfirmNewPasswordChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton tabIndex={-1} onClick={handleShowConfirmPasswordClick}>
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid
                item
                md={2}
                xs={12}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleSaveNewPassword}
                  disabled={passwordLoading}>
                  {passwordLoading ? <CircularProgress size={20} color="inherit" /> : 'Salvar'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Box>
      <TwoFactorAuthenticationModal
        open={openTwoFactorModal}
        handleClose={handleCloseTwoFactorModal}
      />
    </Box>
  )
}
