import { Box, Grid, Typography } from '@mui/material'
import { Ranking } from './Ranking'
import { Highlights } from './Highlights'
import { SmallChart } from './SmallChart'
import { RankingEngagement } from './RankingEngagement'
import { useQuery } from 'react-query'
import { useContext, useState } from 'react'
import homeIcon from '../../assets/home/home-icon.png'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { dashboardClientServices } from '../../services/dashboardClientServices'
import { TDiagnosticsData } from '../../types/diagnostics'
import { TUsersChartData } from '../../types/user'
import { THighlights } from '../../types/highlights'

export const Home = () => {
  const auth = useContext(AuthContext)
  const [healthAreaData, setHealthAreaData] = useState<TDiagnosticsData[]>([])
  const [diagnosticHypothesisData, setDiagnosticHypothesisData] = useState<TDiagnosticsData[]>([])
  const [testsData, setTestsData] = useState<TDiagnosticsData[]>([])
  const [engagementData, setEngagementData] = useState<TDiagnosticsData[]>([])
  const [usersWithActiveJourneyData, setUsersWithActiveJourneyData] = useState<TUsersChartData>()
  const [usersInAttendanceData, setUsersInAttendanceData] = useState<TUsersChartData>()
  const [highligthDiagnosticSectorData, setHighligthDiagnosticSectorData] = useState<THighlights[]>(
    []
  )
  const [highlightHelpServiceData, setHighlightHelpServiceData] = useState<THighlights[]>([])

  const usersWithActiveJourney = useQuery(
    'usersWithActiveJourney',
    async () => {
      return await dashboardClientServices.usersWithActiveJourney()
    },
    {
      onSuccess: (data) => {
        setUsersWithActiveJourneyData(data)
      }
    }
  )

  const healthArea = useQuery(
    'healthArea',
    async () => {
      return await dashboardClientServices.getHealthArea()
    },
    {
      onSuccess: (data) => {
        setHealthAreaData(data)
      }
    }
  )

  const diagnosticHypothesis = useQuery(
    'diagnosticHypothesis',
    async () => {
      return await dashboardClientServices.getDiagnosticHypothesis()
    },
    {
      onSuccess: (data) => {
        setDiagnosticHypothesisData(data)
      }
    }
  )

  const tests = useQuery(
    'tests',
    async () => {
      return await dashboardClientServices.getTests()
    },
    {
      onSuccess: (data) => {
        setTestsData(data)
      }
    }
  )

  const engagement = useQuery(
    'engagement',
    async () => {
      return await dashboardClientServices.getEngagement()
    },
    {
      onSuccess: (data) => {
        setEngagementData(data)
      }
    }
  )

  const usersInAttendance = useQuery(
    'usersInAttendance',
    async () => {
      return await dashboardClientServices.getUsersInAttendance()
    },
    {
      onSuccess: (data) => {
        setUsersInAttendanceData(data)
      }
    }
  )

  const highligthDiagnosticSector = useQuery(
    'highligthDiagnosticSector',
    async () => {
      return await dashboardClientServices.highligthDiagnosticSector()
    },
    {
      onSuccess: (data) => {
        setHighligthDiagnosticSectorData(data)
      }
    }
  )

  const highlightHelpService = useQuery(
    'highlightHelpService',
    async () => {
      return await dashboardClientServices.highligthHelpService()
    },
    {
      onSuccess: (data) => {
        setHighlightHelpServiceData([
          {
            total: data?.total || 0
          }
        ])
      }
    }
  )

  return (
    <>
      <Box display={'flex'} flexDirection={'row'}>
        <Box component={'img'} src={homeIcon} sx={{ width: 74, height: 74 }} />
        <Box sx={{ ml: 2 }}>
          <Typography variant="h3" component="h3">
            Olá, {auth.user?.nickname}
          </Typography>
          <Typography variant="subtitle1">Veja informações sobre a saúde da sua empresa</Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 2
        }}>
        <Highlights
          diagnosticoSectorData={highligthDiagnosticSectorData}
          helpServiceData={highlightHelpServiceData}
          loading={highligthDiagnosticSector.isLoading || highlightHelpService.isLoading}
        />
        <Ranking
          data={healthAreaData}
          loading={healthArea.isLoading}
          title="Áreas da saúde"
          subheader="Essas áreas precisam de mais atenção"
          emptyText="As áreas que precisam de mais atenção serão indicadas quando mais usuários realizarem testes na plataforma"
        />

        <Ranking
          data={diagnosticHypothesisData}
          loading={diagnosticHypothesis.isLoading}
          title="Hipóteses diagnósticas"
          subheader="Esses resultados precisam de mais atenção"
          emptyText="As hipóteses diagnósticas que precisam de mais atenção serão indicadas quando mais usuários realizarem testes na plataforma"
        />

        {/* <RankingEngagement
          header="Ranking"
          title="Operações"
          percentage="79%"
          icon="up"
          position="1"
        /> */}
        <Ranking
          data={testsData}
          loading={tests.isLoading}
          title="Testes"
          subheader="Mais realizados pelos usuários"
          emptyText="Os testes mais realizados pelos usuários aparecerão aqui"
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
            gap: 2
          }}>
          <SmallChart
            title={usersWithActiveJourneyData ? usersWithActiveJourneyData.name : ''}
            data={usersWithActiveJourneyData ? usersWithActiveJourneyData.data : []}
            loading={usersWithActiveJourney.isLoading}
            color="#9575CD"
          />

          <SmallChart
            title={usersInAttendanceData ? usersInAttendanceData.name : ''}
            data={usersInAttendanceData ? usersInAttendanceData.data : []}
            loading={usersInAttendance.isLoading}
            color="#26C6DA"
          />
        </Box>
      </Box>
    </>
  )
}
