import {
  Box,
  Breadcrumbs,
  Typography,
  Grid,
  Button,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Skeleton,
  useTheme,
  ButtonGroup,
  TextField,
  MenuItem,
  Card
} from '@mui/material'

import { useState, useContext, SetStateAction } from 'react'

import { useMutation, useQueryClient, useQuery } from 'react-query'

import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import * as z from 'zod'
import DeleteIcon from '@mui/icons-material/Delete'
import * as XLSX from 'xlsx'
// import ReactFileReader from 'react-file-reader'

import { useLocation, useNavigate } from 'react-router-dom'

import { AuthContext } from '../../../../contexts/auth/AuthContext'
import companyChildBatchServices from '../../../../services/companyChildBatchServices'

import { useSnackbar } from 'notistack'

export const CompanyChildBatchForm = () => {
  const auth = useContext(AuthContext)
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const location = useLocation()
  const navigate = useNavigate()

  // const [id, setId] = useState('')
  // const [cliente, setCliente] = useState('')
  const [companyChilds, setCompanyChilds] = useState<any[]>([])
  const [errors, setErrors] = useState<any[]>([])
  const [count, setCount] = useState(0)

  const mutationPostBatch = useMutation(
    async () => {
      return await companyChildBatchServices.postCompanyChildBatch({
        empresa: auth.user?.empresa,
        empresas_filhas: companyChilds
      })
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Enviado para processamento!', { variant: 'success' })

        setCompanyChilds([])
        setErrors([])
        setCount(0)
      },
      onError: () => {
        enqueueSnackbar('Ocorreu um erro ao enviar o lote', { variant: 'error' })
      }
    }
  )

  const validateMobile = (mobile: string) => {
    if (!mobile || mobile === '') return false
    const regex = /(\(?[0]?[1-9][0-9]\)?)(\.|-|\s)?([9]{1})?[1-9][0-9]{3}(\.|-|\s)?[0-9]{4}/g
    return regex.test(String(mobile).toLowerCase())
  }

  const handleData = (item: any, empresasFilhas: any) => {
    let obj = {
      razao_social: item[0],
      apelido: item[1] ? item[1] : '',
      cnpj: item[2] ? item[2] : ''
    }
    empresasFilhas.push(obj)
  }

  const validateDate = (date: string) => {
    if (!date || date === '') return false
    const regex = /(\d{2}\/\d{2}\/\d{4})/g
    return regex.test(String(date).toLowerCase())
  }

  const handlerXls = (file: any) => {
    const empresasFilhas: SetStateAction<any[]> = []
    let erros: SetStateAction<any[]> = []
    let countAux = 0
    // setLoading(true)
    const reader = new FileReader()
    reader.onload = async function () {
      const dados = new Uint8Array(reader.result as any)
      const workbook = XLSX.read(dados, { type: 'array' })
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const arr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 }) as any[]

      for (let i = 1; i < arr.length; i++) {
        // Considerar a primeira linha como sendo o header
        if (arr[i][0] && arr[i][0].toString() !== '') {
          countAux++
          handleData(arr[i], empresasFilhas)
        }
      }

      if (countAux === 0) {
        enqueueSnackbar('Faça upload de um arquivo com pelo menos um usuário!', {
          variant: 'error'
        })
        return
      }

      setCount(countAux)
      setErrors(erros)
      setCompanyChilds(empresasFilhas)
    }

    setTimeout(() => {
      reader.readAsArrayBuffer(file)
    }, 250)
  }

  function handleImport() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement

    if ((fileInput?.files?.length || 0) > 0) {
      const file = fileInput.files ? fileInput.files[0] : null

      if (file) {
        handlerXls(file)
      }
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <input id="fileInput" type="file" style={{ display: 'none' }} onChange={handleImport} />

      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/')
            }}>
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </Link>
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/company-registration')
            }}>
            Cadastros
          </Link>
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/company-registration/company-batch')
            }}>
            Importação em lote
          </Link>
          <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
            Novo Lote
          </Typography>
        </Breadcrumbs>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <Button
            variant={'text'}
            size={'small'}
            onClick={() => window.open('https://api.hspw.com/exemplo_empresas.xlsx', '_blank')}>
            Exemplo .xlsx
          </Button>
          <Button
            variant={'contained'}
            size={'small'}
            onClick={() => {
              const input = document.getElementById('fileInput')
              if (input) {
                input.click()
              }
              handleImport()
            }}>
            Upload de arquivo
          </Button>
        </Box>
      </Box>

      <Typography variant="h3">Importação em lote</Typography>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 171
            }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              sx={{
                my: 2
              }}>
              <Box sx={{ width: 200 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.primary.main,
                    textAlign: 'center'
                  }}>
                  Encontrados:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.primary.main,
                    textAlign: 'center'
                  }}>
                  {count}
                </Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 171
            }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              sx={{
                my: 2
              }}>
              <Box sx={{ width: 200 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.success.dark,
                    textAlign: 'center'
                  }}>
                  Prontos para envio:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.success.dark,
                    textAlign: 'center'
                  }}>
                  {companyChilds?.length || 0}
                </Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 171
            }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              sx={{
                my: 2
              }}>
              <Box sx={{ width: 200 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.error.dark,
                    textAlign: 'center'
                  }}>
                  Com erro:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.error.dark,
                    textAlign: 'center'
                  }}>
                  {errors?.length || 0}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>

      {errors?.length > 0 && (
        <Box display={'flex'} flexDirection={'column'} gap={2} sx={{ mt: 2 }}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.error.dark
              }}>
              <Box
                component="span"
                sx={{
                  color: theme.palette.error.dark,
                  fontWeight: 700
                }}>
                Dados com erro:
              </Box>{' '}
              Corrija o arquivo XLS e envie novamente. Caso queira enviar assim mesmo, os dados
              abaixo serão ignorados
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Linha do XLS</TableCell>
                  <TableCell>Razão Social</TableCell>
                  <TableCell>Motivo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errors?.length > 0 &&
                  errors.map((err: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{err.linha}</TableCell>
                      <TableCell>{err.razao_social}</TableCell>
                      <TableCell>{err.motivo}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={0}
            rowsPerPage={5}
            page={0}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
            labelRowsPerPage="Linhas por página"
          />
        </Box>
      )}

      <Box display={'flex'} flexDirection={'column'} gap={2} sx={{ mt: 2 }}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.success.dark
            }}>
            Dados prontos para envio:
          </Typography>
          <Button
            variant={'contained'}
            size={'small'}
            color={'success'}
            disabled={mutationPostBatch.isLoading || !companyChilds?.length}
            onClick={() => mutationPostBatch.mutate()}>
            Enviar
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Linha#</TableCell>
                <TableCell>Razão Social</TableCell>
                <TableCell>Apelido</TableCell>
                <TableCell>CNPJ</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companyChilds?.map((companyChild: any, index) => (
                <TableRow key={index}>
                  <TableCell>{companyChild.linha ?? index + 2}</TableCell>
                  <TableCell>{companyChild.razao_social}</TableCell>
                  <TableCell>{companyChild.apelido}</TableCell>
                  <TableCell>{companyChild.cnpj}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={0}
          rowsPerPage={5}
          page={0}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
          labelRowsPerPage="Linhas por página"
        />
      </Box>
    </Box>
  )
}
