import api from './api'
import { TUser } from '../types/user'
import { enqueueSnackbar } from 'notistack'

type twofa = {
  otpauth_url: string
}

export interface IUserServices {
  signIn: (username: string, senha: string, token2FA: string) => Promise<any>
  getAllUsersList: (params: any) => Promise<any>
  getOne: (id: any) => Promise<any>
  putUserPassword: (id: any, senha: any) => Promise<any>
  getUserByVoucher: (voucher: any) => Promise<any>
  postUpdatePasswordByVoucher: (params: any) => Promise<any>
  postUser: (params: any) => Promise<any>
  updateUser: (userId: string, userData: any) => Promise<any>
  updatePassword: (userId: string, password: string) => Promise<any>
  getNew2FA: () => Promise<twofa>
  verify2FA: (token: string) => Promise<any>
  recoveryPassword: (email: string) => Promise<any>
  deleteUser: (userId: string) => Promise<any>
}

export const userServices = () => ({
  signIn: async (username: string, senha: string, token2FA: string) => {
    try {
      const { data } = await api.post('v2/autenticacao/admin', {
        username,
        senha,
        token_2FA: token2FA,
        plataforma: 'web'
      })
      return data.response
    } catch (error: any) {
      if (error?.response?.data?.instructions[0] !== 'Código 2FA não informado!')
        enqueueSnackbar(error?.response?.data?.message, { variant: 'error' })

      throw new Error(error?.response?.data?.instructions[0])
    }
  },
  getAllUsersList: async (params: any) => {
    try {
      const { data } = await api.get('/auth/usuarios/list', { params })
      return data.response
    } catch (e) {
      throw new Error('Erro ao buscar usuários')
    }
  },
  getOne: async (id: any) => {
    try {
      const { data } = await api.get(`/auth/usuarios/${id}`)
      return data.response
    } catch (e) {
      throw new Error('Erro ao buscar usuários')
    }
  },
  putUserPassword: async (id: any, senha: any) => {
    try {
      const response = await api.put(`/auth/v2/usuarios/${id}/senha`, { senha })
      return response
    } catch (e) {
      throw new Error('Erro ao trocar senha')
    }
  },
  getUserByVoucher: async (voucher: any) => {
    try {
      const { data } = await api.get(`/usuarios/voucher/${voucher}`)
      return data.response
    } catch (e) {
      throw new Error('Erro ao buscar usuários')
    }
  },
  postUpdatePasswordByVoucher: async (params: any) => {
    try {
      const { data } = await api.put('/v2/usuarios/senha-voucher', params)
      return data.response
    } catch (e: any) {
      enqueueSnackbar(e?.response?.data?.message, { variant: 'error' })
      throw new Error('Erro ao alterar senha')
    }
  },
  postUser: async (params: any) => {
    try {
      const { data } = await api.post('/auth/v2/usuarios', params)

      return data.response
    } catch (error: any) {
      if (error?.response?.data?.instructions[0] === 'username - Duplicidade não permitida!')
        throw new Error('Nome de usuário já existe')
      else throw new Error('Erro ao cadastrar usuário')
    }
  },
  updateUser: async (userId: string, userData: any) => {
    try {
      const { data } = await api.put(`auth/v2/usuarios/${userId}`, userData)
      return data.response
    } catch (error: any) {
      if (error?.response?.data?.instructions[0] === 'username - Duplicidade não permitida!')
        throw new Error('Nome de usuário já existe')
      else throw new Error('Erro ao cadastrar usuário')
    }
  },

  updatePassword: async (userId: string, password: string) => {
    try {
      const { data } = await api.put(`auth/v2/usuarios/${userId}/senha`, {
        senha: password
      })
      return data.response
    } catch (error: any) {
      throw new Error('Erro ao atualizar senha')
    }
  },

  getNew2FA: async () => {
    try {
      const { data } = await api.get(`auth/usuarios/2FA`)
      return data.response
    } catch (error: any) {
      throw new Error('Erro ao gerar 2FA')
    }
  },

  verify2FA: async (token: string) => {
    try {
      const { data } = await api.put(`auth/usuarios/2FA/verificar`, {
        token
      })
      return data.response
    } catch (error: any) {
      enqueueSnackbar(error?.response?.data?.message, { variant: 'error' })

      throw new Error('Erro ao verificar 2FA')
    }
  },

  recoveryPassword: async (email: string) => {
    try {
      const { data } = await api.post(`usuarios/esqueci-senha/dashboard`, {
        email
      })
      return data.response
    } catch (error: any) {
      throw new Error('Erro ao solicitar recuperação de senha')
    }
  },

  deleteUser: async (userId: string) => {
    try {
      const { data } = await api.delete(`auth/usuarios/${userId}`)
      return data.response
    } catch (error: any) {
      throw new Error('Erro ao deletar usuário')
    }
  }
})

export default <IUserServices>userServices()
