import { Box, Button, Card, TextField, Typography, useTheme } from '@mui/material'
import logo from '../../assets/login/logo.png'
import userServices from '../../services/userServices'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import * as z from 'zod'

const schema = z.object({
  email: z.string().email('E-mail inválido')
})

export const ForgotPassword = () => {
  type Schema = z.infer<typeof schema>

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (data: Schema) => userServices.recoveryPassword(data.email),
    {
      onSuccess: () => {
        enqueueSnackbar('Enviamos a solicitação de troca de senha via e-mail!', {
          variant: 'success'
        })
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )

  const onSubmit = (data: Schema) => {
    mutate(data)
  }

  const handleBackToLogin = () => {
    navigate('/')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        p: 2
      }}>
      <Box component={'img'} src={logo} sx={{ width: 192 }} />
      <Card sx={{ width: '95%', maxWidth: 546, p: 2, pt: 4 }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Typography variant={'h5'}>Esqueceu sua senha?</Typography>
            <Typography variant={'body2'}>
              Informe o e-mail utilizado na sua conta HSPW para recuperar sua senha
            </Typography>
          </Box>
          <TextField
            fullWidth
            label={'E-mail'}
            variant={'outlined'}
            {...register('email', { required: true })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Button fullWidth variant={'contained'} type="submit">
            Recuperar minha senha
          </Button>
          <Typography
            onClick={handleBackToLogin}
            variant={'body2'}
            sx={{ textAlign: 'right', color: theme.palette.primary.main, cursor: 'pointer' }}>
            Voltar para a tela de login
          </Typography>
        </Box>
      </Card>
    </Box>
  )
}
