import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Switch,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  MenuItem,
  TablePagination,
  DialogContentText,
  DialogActions,
  Modal,
  Avatar,
  Card,
  Skeleton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useContext, useState } from 'react'
import { companyServices } from '../../services/companyChildServices'
import { companyBranchServices } from '../../services/companyBranchServices'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useSnackbar } from 'notistack'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import moment from 'moment'

import { CompanyBranchForm } from './CompanyBranchForm'

const ModalDelete = (props: any) => {
  const { open, onClose, empresa } = props
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          position: 'relative',
          outline: 0
        }}>
        <Card
          sx={{
            outline: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            pt: 10
          }}>
          <Avatar
            sx={{
              bgcolor: '#FF9800',
              position: 'absolute',
              width: 100,
              height: 100,
              top: -50,
              left: 'calc(50% - 50px)'
            }}>
            <WarningAmberIcon sx={{ width: 40, height: 40 }} />
          </Avatar>
          <Typography variant="h4">Deseja mesmo remover a empresa {empresa} ?</Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            Esta ação não poderá ser desfeita.
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button sx={{ mr: 2 }} onClick={onClose}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => {
                props.mutationDelete.mutate()
                onClose()
              }}>
              Remover
            </Button>
          </Box>
        </Card>
      </Box>
    </Modal>
  )
}

export const CompanyBranchTab = () => {
  const theme = useTheme()
  const auth = useContext(AuthContext)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openSituationChange, setOpenSituationChange] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [item, setItem] = useState({ id: '', nome: '', empresa: '', situacao: '' })
  const [formData, setFormData] = useState<TCompany>({} as TCompany)

  const { data, isLoading } = useQuery('companyBranch', async () => {
    return await companyBranchServices.getAllCompanyBranch({
      empresa: auth.user?.empresa
    })
  })

  const mutationSituation = useMutation(
    async () => {
      return await companyBranchServices
        .putCompanyBranch({
          ...item,
          situacao: item.situacao === 'ATIVO' ? 'INATIVO' : 'ATIVO'
        })
        .then(() => {
          queryClient.invalidateQueries('companyBranch')
        })
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Situação alterada com sucesso!', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Erro ao alterar situação!', { variant: 'error' })
      }
    }
  )

  // const mutationDelete = useMutation(
  //   async () => {
  //     return await companyServices
  //       .deleteCompanyChild({
  //         empresa_filha: item.id
  //       })
  //       .then(() => {
  //         queryClient.invalidateQueries('companies')
  //       })
  //   },
  //   {
  //     onSuccess: () => {
  //       enqueueSnackbar('Empresa removida com sucesso!', { variant: 'success' })
  //     },
  //     onError: () => {
  //       enqueueSnackbar('Erro ao remover empresa!', { variant: 'error' })
  //     }
  //   }
  // )

  type TCompany = {
    id: string
    nome: string
    empresa_filha: {
      id: string
      apelido: string
    }
    situacao: string
  }

  const handleEditRow = ({ id, nome, empresa_filha, situacao }: TCompany) => {
    setFormData({ id, nome, empresa_filha, situacao })
    setOpen(true)
  }

  isLoading && <Typography variant="h4">Carregando...</Typography>

  const SituationDialog = () => {
    return (
      <Dialog open={openSituationChange} maxWidth="md">
        <DialogTitle variant="h6" sx={{ mt: 2 }}>
          Confirme sua ação
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            Deseja mesmo {item.situacao === 'ATIVO' ? 'inativar' : 'ativar'} a empresa {item.nome} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSituationChange(false)
            }}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              mutationSituation.mutate()
              setOpenSituationChange(false)
            }}
            color={'warning'}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Box>
      {openSituationChange && <SituationDialog />}
      {/* {showModal && (
        <ModalDelete
          open={showModal}
          onClose={() => setShowModal(false)}
          empresa={item.nome}
          mutationDelete={mutationDelete}
        />
      )} */}
      {open && <CompanyBranchForm formData={formData} setOpen={setOpen} />}

      <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
        Filiais
      </Typography>

      <Button
        variant="contained"
        size="small"
        sx={{ backgroundColor: '#9C27B0' }}
        startIcon={<AddIcon />}
        onClick={() => {
          setFormData({
            id: '',
            nome: '',
            empresa_filha: {
              id: '',
              apelido: ''
            },
            situacao: ''
          })
          setOpen(true)
        }}>
        Nova filial
      </Button>
      <Button
        disabled
        variant="outlined"
        size="small"
        sx={{
          color: '#9C27B0',
          borderColor: '#9C27B0',
          ml: 2,
          '&:hover': { color: `${theme.palette.primary.main}` }
        }}>
        Lotes
      </Button>

      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Filial</TableCell>
              <TableCell>Empresa</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>data de criação</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Box sx={{ mb: 1 }}>
                    <Skeleton variant="rectangular" height={60} width="100%" />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Skeleton variant="rectangular" height={60} width="100%" />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Skeleton variant="rectangular" height={60} width="100%" />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {data?.map((company: any) => (
              <TableRow key={company._id}>
                <TableCell>{company.nome}</TableCell>
                <TableCell>{company.empresa_filha?.apelido}</TableCell>
                <TableCell>
                  <Switch
                    onChange={() => {
                      setItem(company)
                      setOpenSituationChange(true)
                    }}
                    checked={company.situacao === 'ATIVO'}
                  />
                </TableCell>
                <TableCell>{moment(company.data_criacao).format('DD/MM/YYYY HH:mm')}</TableCell>
                <TableCell>
                  <Tooltip title="Editar" placement="top" arrow>
                    <IconButton
                      size="small"
                      sx={{ color: `${theme.palette.primary.main}` }}
                      onClick={() => handleEditRow(company)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title="Deletar" placement="top" arrow>
                    <IconButton
                      size="small"
                      sx={{ color: `${theme.palette.error.dark}` }}
                      onClick={() => {
                        setItem(company)
                        setShowModal(true)
                      }}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip> */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length || 0}
        rowsPerPage={5}
        page={0}
        onPageChange={() => {}}
        onRowsPerPageChange={() => {}}
        labelRowsPerPage="Linhas por página"
      /> */}
    </Box>
  )
}
