import { useState, useContext } from 'react'
import { useSnackbar } from 'notistack'
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  ButtonGroup,
  Button,
  useTheme,
  IconButton,
  TextField,
  Skeleton
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'
import FilterListIcon from '@mui/icons-material/FilterList'
import EditIcon from '@mui/icons-material/Edit'
import InfoIcon from '@mui/icons-material/Info'
import DraftsIcon from '@mui/icons-material/Drafts'
import moment from 'moment'
import { helpRequestsServices } from '../../services/helpRequestsServices'
import { FilterModal } from './FilterModal'
import { AuthContext } from '../../contexts/auth/AuthContext'

export const HelpRequests = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const auth = useContext(AuthContext)
  const filterCache = JSON.parse(localStorage.getItem('helpRequestFilter') || '{}')

  const [showFilter, setShowFilter] = useState(false)
  const [helpRequests, setHelpRequests] = useState([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(25)
  const [totalDocs, setTotalDocs] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [filters, setFilters] = useState<any>(
    filterCache ? filterCache : { status: ['EM ATENDIMENTO', 'AGUARDANDO'] }
  )

  const formataAssunto: any = { SAUDE_MENTAL: 'Saúde Mental', SAUDE_FISICA: 'Saúde Física' }

  let filterD = {
    atendimento_via: 'HELP_TEAM_HSPW',
    empresa: undefined,
    status: ['EM ATENDIMENTO', 'AGUARDANDO']
  }

  const { data, isLoading } = useQuery(
    'helpRequests',
    async () => {
      return await helpRequestsServices.getAllHelpRequests({
        paginate: true,
        page: 1,
        limit: limit,
        ...filterD,
        ...filters
      })
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setHelpRequests(data.docs || [])
        setPage(data.page - 1)
        setTotalDocs(data.totalDocs)
      },
      onError: () => {
        enqueueSnackbar('Erro ao buscar pedidos de ajuda!', { variant: 'error' })
      }
    }
  )

  const formatCriticalityField = (type: string) => {
    if (type === '40_EMERGENCIA') {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InfoIcon style={{ color: '#DC3545', fontSize: 16, marginRight: 5 }} />
          <div style={{ color: '#DC3545' }}>Emergência</div>
        </div>
      )
    }

    if (type === '30_URGENCIA') {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InfoIcon style={{ color: '#FF9800', fontSize: 16, marginRight: 5 }} />
          <div style={{ color: '#FF9800' }}>Urgência</div>
        </div>
      )
    }

    if (type === '20_POUCO_URGENTE') {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InfoIcon style={{ color: '#4CAF50', fontSize: 16, marginRight: 5 }} />
          <div style={{ color: '#4CAF50' }}>Pouca urgência</div>
        </div>
      )
    }

    if (type === '10_NAO_URGENTE') {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <InfoIcon style={{ color: '#03A9F4', fontSize: 16, marginRight: 5 }} />
          <div style={{ color: '#03A9F4' }}>Não urgente</div>
        </div>
      )
    }

    return ''
  }

  const renderPedidoAjudaSituation = (situation: string) => {
    switch (situation) {
      case 'EM ATENDIMENTO':
        return <span style={{ color: '#ED6C02' }}>Em atendimento</span>
      case 'AGUARDANDO':
        return <span style={{ color: '#9E9E9E' }}>Aguardando</span>
      case 'CONCLUIDO':
        return <span style={{ color: '#4CAF50' }}>Concluído</span>
      default:
        return <span></span>
    }
  }

  const renderPedidoAjudaChatSituation = (situation: string, _id: string) => {
    const buttonStatus = (texto: string, bgColor: string, color: string, icon: boolean) => {
      return (
        <div
          style={{
            padding: 4,
            borderRadius: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: color,
            textAlign: 'center',
            backgroundColor: bgColor
          }}
          onClick={() => {
            navigate('/help-requests/chat', {
              state: { chat: _id }
            })
          }}>
          {icon && <DraftsIcon style={{ fontSize: 15, marginRight: 5 }} />}
          {texto}
        </div>
      )
    }

    switch (situation) {
      case 'CONCLUIDO':
        return buttonStatus('Finalizado', '#bbc0c4', '#000', false)
      case 'AGUARDANDO':
        return buttonStatus('Iniciar chat', '#1976D2', '#fff', true)
      case 'NOVA MENSAGEM':
        return buttonStatus('Nova mensagem', '#a4ccf4', '#000', false)
      case 'ABERTO':
        return buttonStatus('Aberto', '#E8F5E9', '#1B5E20', false)
      default:
        return <span className="text-primary bold"></span>
    }
  }

  const mutationSearch = useMutation(
    async (filter: any) => {
      return await helpRequestsServices.getAllHelpRequests({
        paginate: true,
        page: filter.page,
        limit: filter.limit,
        ...filters,
        ...filter
      })
    },
    {
      onSuccess: (data) => {
        setHelpRequests(data.docs || [])
        setPage(data.page - 1)
        setTotalDocs(data.totalDocs)
      },
      onError: () => {
        enqueueSnackbar('Erro ao buscar pedidos de ajuda!', { variant: 'error' })
      }
    }
  )

  return (
    <Box display={'flex'} flexDirection={'column'} gap={10}>
      <FilterModal
        open={showFilter}
        onClose={() => setShowFilter(false)}
        setFilters={setFilters}
        onFilter={(filter) => {
          mutationSearch.mutate(filter)
        }}
      />
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
        <Typography variant="h3">Pedidos de ajuda</Typography>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          {/* <ButtonGroup size="small" variant="outlined" aria-label="small outlined button group">
            <Button variant="contained" startIcon={<ContentPasteIcon />}>
              Exportar
            </Button>
          </ButtonGroup> */}
        </Box>
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
          <TextField
            id="outlined-basic"
            label="Pesquise pedidos de ajuda por código ou usuário"
            variant="outlined"
            //change when enter is pressed
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                mutationSearch.mutate({ username_codigo: searchText })
              }
            }}
            sx={{ flex: 1 }}
          />
          <Box display={'flex'} flexDirection={'row'} gap={2}>
            <Button
              size="small"
              variant="outlined"
              startIcon={<FilterListIcon />}
              onClick={() => setShowFilter(true)}>
              Filtros
            </Button>
            {/* {filtered ? (
              <IconButton aria-label="clear" color="primary">
                <CloseOutlined />
              </IconButton>
            ) : null} */}
          </Box>
        </Box>

        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Código</TableCell>
                <TableCell>Usuário</TableCell>
                <TableCell>{auth?.user && auth?.user?.admin ? 'Cliente' : 'Empresa'}</TableCell>
                <TableCell>Assunto</TableCell>
                <TableCell>Grau do Diagnóstico</TableCell>
                <TableCell>Data do pedido</TableCell>
                <TableCell>Status Atendimento</TableCell>
                <TableCell>Status Chat</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    <Box sx={{ mb: 1 }}>
                      <Skeleton variant="rectangular" height={70} width="100%" />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Skeleton variant="rectangular" height={70} width="100%" />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Skeleton variant="rectangular" height={70} width="100%" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {helpRequests.map((request: any) => (
                <TableRow key={request._id}>
                  <TableCell
                    sx={{
                      maxWidth: 150, // Defina a largura máxima desejada da célula
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                    {request?.codigo}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: 100, // Defina a largura máxima desejada da célula
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                    {request?.usuario?.username}
                  </TableCell>
                  <TableCell
                    sx={{
                      maxWidth: 100, // Defina a largura máxima desejada da célula
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                    {auth?.user && auth?.user?.admin ? request?.empresa : request?.empresa_filha}
                  </TableCell>
                  <TableCell>
                    {request?.assunto === 'SAUDE_MENTAL' || request?.assunto === 'SAUDE_FISICA'
                      ? formataAssunto[request?.assunto]
                      : request?.assunto}
                  </TableCell>
                  <TableCell>{formatCriticalityField(request?.criticidade)}</TableCell>
                  <TableCell>
                    <b>{moment(request?.data_criacao).format('DD/MM/YYYY')}</b>
                    <br />
                    {moment(request?.data_criacao).format('HH:mm:ss')}
                  </TableCell>
                  <TableCell>{renderPedidoAjudaSituation(request?.status)}</TableCell>
                  <TableCell>
                    {renderPedidoAjudaChatSituation(request?.status_chat, request?._id)}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Editar" placement="top" arrow>
                      <IconButton
                        size="small"
                        sx={{ color: `${theme.palette.primary.main}` }}
                        onClick={() => {
                          navigate('/help-requests/edit', {
                            state: { data: request }
                          })
                        }}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          // count={data?.length || 0}
          count={totalDocs || 0}
          rowsPerPage={limit || 25}
          page={page || 0}
          onPageChange={(e, page) => {
            mutationSearch.mutate({ page: page + 1, limit: limit })
          }}
          onRowsPerPageChange={(e) => {
            setLimit(parseInt(e.target.value, 10))
            mutationSearch.mutate({ page: page + 1, limit: parseInt(e.target.value, 10) })
          }}
          labelRowsPerPage="Linhas por página"
        />
      </Box>
    </Box>
  )
}
