import api from './api'

export interface ICompanyChildBatchServices {
  getAllBatchForList: (params: any) => Promise<any[]>
  deleteCompanyChildBatch: (batchId: string) => Promise<any>
  postCompanyChildBatch: (batch: any) => Promise<any>
}

export const companyChildBatchServices = () => ({
  getAllBatchForList: async (params: any) => {
    try {
      const { data } = await api.get('auth/empresa-filhas-lote', {
        params
      })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar lotes')
    }
  },
  deleteCompanyChildBatch: async (batchId: string) => {
    try {
      const { data } = await api.delete(`auth/empresa-filhas-lote/${batchId}`)
      return data.response
    } catch (error) {
      throw new Error('Erro ao deletar lote')
    }
  },
  postCompanyChildBatch: async (batch: any) => {
    try {
      const { data } = await api.post('auth/empresa-filhas-lote', batch)
      return data.response
    } catch (error) {
      throw new Error('Erro ao cadastrar lote')
    }
  }
})

export default <ICompanyChildBatchServices>companyChildBatchServices()
