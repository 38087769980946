import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Chip,
  Skeleton
} from '@mui/material'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { useQuery } from 'react-query'
import DownloadIcon from '@mui/icons-material/Download'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useContext } from 'react'
import moment from 'moment'
import { companyCycleServices } from '../../services/companyCycleService'

export const Reports = () => {
  const auth = useContext(AuthContext)

  const { data, isLoading } = useQuery('companyCycles', async () => {
    return await companyCycleServices.getAllCompanyCycles({
      empresa: auth.user?.empresa,
      tipo: 'PERIODICA'
    })
  })

  const renderSituation = (situation: string) => {
    switch (situation) {
      case 'ATIVO':
        return <Typography style={{ color: '#4CAF50', fontWeight: 'bold' }}>Ativo</Typography>
      case 'INATIVO':
        return <Typography style={{ color: '#F44336', fontWeight: 'bold' }}>Inativo</Typography>
      case 'FINALIZADO':
        return <Typography style={{ color: '#B0BEC5', fontWeight: 'bold' }}>Finalizado</Typography>
      default:
        return <Typography style={{ color: '#5569FF', fontWeight: 'bold' }}>{situation}</Typography>
    }
  }

  const getTotalQuestions = (forms: any) => {
    let total = 0
    for (let i = 0; i < forms.length; i++) total = total + forms[i].questoes_quantidade
    return total
  }

  const formatPhoneNumber = (str: string) => {
    const cleaned = ('' + str).replace(/\D/g, '').substring(0, 11)
    const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return str
  }

  const s2ab = (s: any) => {
    var buf = new ArrayBuffer(s.length)
    var view = new Uint8Array(buf)
    for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const handleGenerateXLS = async (id: string) => {
    const company = auth.user?.empresa || ''
    const response = await companyCycleServices.getCycleUsersProgress(id, company)
    let data = []
    for (const [, user] of response.entries()) {
      data.push({
        Matricula: user.matricula,
        Empresa: user.empresa_filha,
        Filial: user.filial,
        'Situação Empresa': user.situacao_empresa,
        Username: user.username || '',
        Telefone: formatPhoneNumber(user.telefone) || '--',
        'Departamento Pai': user.departamento_pai,
        Departamento: user.departamento,
        'Aut. Acesso a Dados': user.autoriza_acesso_dados ? 'Sim' : 'Não',
        'Status Usuario': user.situacao,
        Ciclo: user.ciclo_descricao || '--',
        'Total de Questoes': user.total_questoes_ciclo || 0,
        'Total de Respostas': user.qtde_respostas || 0,
        '% Concluído': user.percent_ciclo || 0,
        Pontuacao: user.pontuacao_geral || 0
      })
    }

    let cols = [
      { wpx: 100, wch: 100 }, // matricula
      { wpx: 200, wch: 200 }, // empresa filha
      { wpx: 200, wch: 200 }, //filial
      { wpx: 100, wch: 100 }, //situacao empresa
      { wpx: 100, wch: 100 }, // username
      { wpx: 100, wch: 100 }, // telfone
      { wpx: 200, wch: 200 }, // depto pai
      { wpx: 200, wch: 200 }, // depto
      { wpx: 200, wch: 200 }, // autoriza dados
      { wpx: 100, wch: 100 }, // situacao user
      { wpx: 100, wch: 100 }, // ciclo
      { wpx: 100, wch: 100 }, // total questoes
      { wpx: 100, wch: 100 }, // total respostas
      { wpx: 100, wch: 100 }, // % do ciclo
      { wpx: 80, wch: 80 } // Pontuacao
    ]

    var ws = XLSX.utils.json_to_sheet(data)
    var wb = XLSX.utils.book_new()
    ws['!cols'] = cols
    XLSX.utils.book_append_sheet(wb, ws, 'Usuários HSPW')

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
    saveAs(
      new Blob([s2ab(wbout)], { type: 'application/octet-stream' }),
      'HSPW_Situacao_ciclo.xlsx'
    )
    //})
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={5}>
      <Box>
        <Typography variant={'h3'}>Relatórios</Typography>
        {/* <Typography variant={'subtitle1'}>Extraia relatórios de ciclos pré-configurados</Typography> */}
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Checkup de saúde</TableCell>
                <TableCell>Data de início</TableCell>
                <TableCell>Data limite</TableCell>
                <TableCell>Questões / Dia</TableCell>
                <TableCell>Situação</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box
                      sx={{
                        width: '100%',
                        mb: 1
                      }}>
                      <Skeleton variant={'rectangular'} height={90} width="100%" />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        mb: 1
                      }}>
                      <Skeleton variant={'rectangular'} height={90} width="100%" />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        mb: 1
                      }}>
                      <Skeleton variant={'rectangular'} height={90} width="100%" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {data?.map((request: any) => (
                <TableRow key={request._id}>
                  <TableCell>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      sx={{
                        alignItems: 'flex-start',
                        gap: 1
                      }}>
                      {request?.descricao}
                      {request?.formularios?.length > 0 && (
                        <>
                          <Chip
                            label={`Formulários: ${request.formularios.length}`}
                            color="primary"
                            sx={{}}
                          />
                          <Chip
                            label={`Questões: ${getTotalQuestions(request.formularios)}`}
                            color="primary"
                            sx={{}}
                          />
                        </>
                      )}
                      {request?.habilita_fit_cultural && (
                        <Chip label={`Fit cultural`} color="info" sx={{}} />
                      )}
                      {request?.frequencia_aplicacao === 'RECORRENTE' && (
                        <Chip label={`Recorrente`} color="warning" sx={{}} />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>{moment(request?.data_inicio).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>{moment(request?.limite).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Typography
                      variant={'body2'}
                      sx={{
                        fontWeight: 'bold'
                      }}>
                      {request?.quantidade_questoes_diarias}
                    </Typography>
                  </TableCell>
                  <TableCell>{renderSituation(request?.situacao)}</TableCell>
                  <TableCell>
                    <Box display={'flex'} flexDirection={'row'} gap={1}>
                      <Button
                        color={'primary'}
                        startIcon={<DownloadIcon />}
                        onClick={() => handleGenerateXLS(request._id)}
                        disabled={request['situacao'] === 'REPROCESSANDO'}>
                        Gerar XLS
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
