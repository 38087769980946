import {
  Box,
  Breadcrumbs,
  Typography,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  MenuItem,
  Switch,
  Button,
  Autocomplete
} from '@mui/material'

import { useEffect, useState, useContext, forwardRef } from 'react'

import { enqueueSnackbar } from 'notistack'

import { useMutation, useQueryClient, useQuery } from 'react-query'

import InputMask from 'react-input-mask'

import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { useLocation, useNavigate } from 'react-router-dom'

import { AuthContext } from '../../../contexts/auth/AuthContext'

import { companySectorServices } from '../../../services/companySectorServices'
import { companyPositionServices } from '../../../services/companyPositionServices'
import { companyServices } from '../../../services/companyChildServices'
import { companyBranchServices } from '../../../services/companyBranchServices'
import userServices from '../../../services/userServices'
import groupServices from '../../../services/groupServices'
import { profile } from 'console'

interface UserFormProps {
  formData?: any
}

const DateMaskCustom = forwardRef<HTMLElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <InputMask {...other} mask="99/99/9999" maskChar={null} inputRef={ref} onChange={onChange} />
  )
})

export const UserRegistration = () => {
  const auth = useContext(AuthContext)
  const queryClient = useQueryClient()

  const location = useLocation()
  const navigate = useNavigate()

  const { operation, data, type } = location.state || { operation: 'cad', data: null, type: 'user' }

  const passwordSchema = z
    .object({
      senha: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
      confirmar_senha: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres')
    })
    .superRefine(({ senha, confirmar_senha }, ctx) => {
      if (senha !== confirmar_senha) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'As senhas não coincidem',
          path: ['confirmar_senha']
        })
      }
    })

  const schema =
    operation === 'cad'
      ? z.object({
          username: z.string().min(1, 'Campo obrigatório'),
          nova_senha: z.string().min(8, 'Campo obrigatório'),
          matricula: z.string().optional(),
          situacao_empresa: z.string().optional(),
          ferias_ultimo_ano: z.string().optional(),
          faltas_ano: z.string().optional(),
          atestado_ano: z.string().optional(),
          modelo_contrato: z.string().optional(),
          data_admissao: z.string().optional(),
          empresa_filha: z.string().min(1, 'Campo obrigatório'),
          empresa_filial: z.string().min(1, 'Campo obrigatório'),
          empresa_setor: z.string().min(1, 'Campo obrigatório'),
          empresa_cargo: z.string().min(1, 'Campo obrigatório')
        })
      : z.object({
          username: z.string().min(1, 'Campo obrigatório'),
          nova_senha: z.string().optional(),
          matricula: z.string().optional(),
          situacao_empresa: z.string().optional(),
          ferias_ultimo_ano: z.string().optional(),
          faltas_ano: z.string().optional(),
          atestado_ano: z.string().optional(),
          modelo_contrato: z.string().optional(),
          data_admissao: z.string().optional(),
          empresa_filha: z.string().min(1, 'Campo obrigatório'),
          empresa_filial: z.string().min(1, 'Campo obrigatório'),
          empresa_setor: z.string().min(1, 'Campo obrigatório'),
          empresa_cargo: z.string().min(1, 'Campo obrigatório')
        })

  type Schema = z.infer<typeof schema>
  type passSchema = z.infer<typeof passwordSchema>

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    resetField,
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const {
    register: registerPass,
    handleSubmit: handleSubmitPass,
    formState: { errors: errorsPass }
  } = useForm<passSchema>({
    resolver: zodResolver(passwordSchema)
  })

  let formData = data || {
    matricula: '',
    situacao: 'RASCUNHO',
    admin: false,
    empresa: '',
    telefone: '',
    empresa_filha: '',
    empresa_filial: '',
    empresa_cargo: '',
    empresa_setor: '',
    situacao_empresa: '',
    modelo_contrato: '',
    ferias_ultimo_ano: 'false',
    faltas_ano: '',
    atestado_ano: '',
    username: '',
    acesso_testes: false,
    data_admissao: '',
    data_demissao: '',
    data_afastamento: '',
    recusa_utilizacao: false,
    etapa_selecao: '',
    modelo_pulseira: ''
  }

  let formPasswordData = {
    senha: '',
    confirmar_senha: ''
  }
  const [toggle_password, setTogglePassword] = useState(false)
  // const [companyChildren, setCompanyChildren] = useState<any[]>([])
  const [filteredBranchs, setFilteredBranchs] = useState<any[] | null>(null)
  const [loadingFilteredBranchs, setLoadingFilteredBranchs] = useState(true)
  const [adminCli, setAdminCli] = useState(data?.admin_empresa || false)
  const [adminCad, setAdminCad] = useState(data?.acesso_somente_cadastro || false)

  const filterBranchs = (empresa: string) => {
    setLoadingFilteredBranchs(true)
    resetField('empresa_filial')

    setFilteredBranchs(
      companyBranchs.filter((b: any) => {
        return b.empresa === empresa
      })
    )

    setLoadingFilteredBranchs(false)
  }

  const onSubmitPassword = (data: passSchema) => {
    mutationPutPassword.mutate(data)
  }

  const onSubmit = (data: Schema) => {
    const payloadCreate = {
      ...data,
      empresa: auth.user?.empresa,
      acesso_somente_cadastro: adminCad,
      admin_empresa: adminCli,
      senha: data.nova_senha,
      perfil: profiles.find((p: any) => p.valor_padrao == true)?.id,
      situacao: 'RASCUNHO'
    }
    // data.ferias_ultimo_ano = 'false'
    const payloadPut = {
      ...data,
      _id: formData?._id,
      acesso_somente_cadastro: adminCad,
      admin_empresa: adminCli,
      empresa: auth.user?.empresa,
      situacao: formData.situacao
    }
    formData?._id ? mutationPutUser.mutate(payloadPut) : mutationPostUser.mutate(payloadCreate)
  }

  const { data: responseChildren, isLoading: childrenLoading } = useQuery(
    'companyChildren',
    async () => {
      return await companyServices.getAllCompaniesChild({
        empresa: auth.user?.empresa,
        situacao: 'ATIVO',
        removido: false
      })
    }
  )

  const companyChildren =
    responseChildren?.map((c: any) => {
      return { value: c._id, label: c.razao_social }
    }) || []

  const { data: responseBranchs, isLoading: branchsLoading } = useQuery(
    'companyBranchs',
    async () => {
      return await companyBranchServices.getAllCompanyBranch({
        empresa: auth.user?.empresa,
        situacao: 'ATIVO',
        removido: false
      })
    }
  )

  const companyBranchs = responseBranchs?.map((b: any) => {
    return { value: b._id, label: b.nome, empresa: b.empresa_filha._id }
  })

  useEffect(() => {
    if (branchsLoading === false) {
      const filtered = companyBranchs.filter((b: any) => {
        return b.empresa === formData.empresa_filha_id
      })

      setFilteredBranchs(filtered)
    }
  }, [branchsLoading])

  const { data: responsePositions, isLoading: positionsLoading } = useQuery(
    'companyPositions',
    async () => {
      return await companyPositionServices.getAllCompanyPosition({
        empresa: auth.user?.empresa,
        situacao: 'ATIVO',
        removido: false
      })
    }
  )

  const companyPositions =
    responsePositions?.map((p: any) => {
      return { value: p._id, label: p.nome }
    }) || []

  const { data: resSectors, isLoading: sectorsLoading } = useQuery('companySectors', async () => {
    return await companySectorServices.getAllCompanySector({
      empresa: auth.user?.empresa,
      situacao: 'ATIVO',
      removido: false
    })
  })

  const companySectors =
    resSectors?.map((k: any) => {
      const nome_pai = k['empresa_setor_pai'] ? k['empresa_setor_pai']['nome'] + ' > ' : ''
      return {
        value: k['_id'],
        label: nome_pai + k['nome']
      }
    }) || []

  const { data: profiles, isLoading: profilesLoading } = useQuery('profiles', async () => {
    return await groupServices.getAll({
      tipo: 'PERFIL_USUARIO'
    })
  })

  const mutationPostUser = useMutation(
    async (data: Schema) => {
      return await userServices.postUser(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
        enqueueSnackbar('Salvo com sucesso!', { variant: 'success' })
        navigate('/users')
      },
      onError: (error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    }
  )

  const mutationPutUser = useMutation(
    async (data: Schema) => {
      return await userServices.updateUser(formData?._id || '', data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('users')
        enqueueSnackbar('Salvo com sucesso!', { variant: 'success' })
        navigate('/users')
      },
      onError: (error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    }
  )

  const mutationPutPassword = useMutation(
    async (data: passSchema) => {
      return await userServices.putUserPassword(auth.user && auth.user?.id, data.senha)
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Senha alterada com sucesso!', { variant: 'success' })
      },
      onError: (error: any) => {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    }
  )

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          color="inherit"
          onClick={() => {
            navigate('/')
          }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          color="inherit"
          onClick={() => {
            navigate('/users')
          }}>
          Usuários
        </Link>
        <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
          {operation === 'cad' ? 'Novo ' : 'Edição de '}
          {type === 'candidato' ? 'candidato' : 'usuário'}
        </Typography>
      </Breadcrumbs>

      <Typography variant="h3">
        {operation === 'cad' ? 'Novo ' : 'Edição de '}
        {type === 'candidato' ? 'candidato' : 'usuário'}
      </Typography>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={2}
        component={'form'}
        onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              defaultValue={formData.username}
              {...register('username')}
              error={!!errors.username}
              helperText={errors.username?.message || 'Para acesso ao sistema'}
              key={1}
            />
          </Grid>
          {operation === 'cad' && (
            <Grid item xs={12} md={4}>
              <TextField
                label="Senha"
                variant="outlined"
                fullWidth
                defaultValue={formData.nova_senha}
                {...register('nova_senha')}
                error={!!errors.nova_senha}
                helperText={
                  errors.nova_senha?.message || 'Senha padrão para o primeiro acesso ao sistema'
                }
                key={1}
                type={toggle_password ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() => setTogglePassword(!toggle_password)}
                        onMouseDown={(event) => event.preventDefault()}>
                        {toggle_password ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          )}
        </Grid>

        {type !== 'candidato' && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box display={'flex'} flexDirection={'column'} gap={0}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Acesso limitado
                </Typography>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={2}
                  sx={{ p: 3, border: '1px solid grey', borderBottom: 'none' }}>
                  <Typography variant="h4">Administrador do cliente</Typography>
                  <Switch
                    onChange={() => {
                      setAdminCli(!adminCli)
                    }}
                    checked={adminCli}
                  />
                </Box>
                <Box
                  display={'flex'}
                  flexDirection={'row'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  gap={2}
                  sx={{ p: 3, border: '1px solid grey' }}>
                  <Typography variant="h4">Administrador de cadastros</Typography>
                  <Switch
                    onChange={() => {
                      setAdminCad(!adminCad)
                    }}
                    checked={adminCad}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}

        <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
          Relação com a empresa
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Matrícula"
              variant="outlined"
              fullWidth
              defaultValue={formData.matricula}
              {...register('matricula')}
              error={!!errors.matricula}
              helperText={errors.matricula?.message}
              key={1}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Situação na empresa"
              variant="outlined"
              fullWidth
              select
              disabled={type === 'candidato'}
              defaultValue={type === 'candidato' ? 'CANDIDATO' : formData.situacao_empresa}
              {...register('situacao_empresa')}
              error={!!errors.situacao_empresa}
              helperText={errors.situacao_empresa?.message}>
              <MenuItem value="NORMAL">Normal</MenuItem>
              <MenuItem value="AFASTADO_TEMP">Afastado Temp.</MenuItem>
              <MenuItem value="FERIAS">Férias</MenuItem>
              <MenuItem value="DESLIGADO">Desligado</MenuItem>
              {type === 'candidato' && <MenuItem value="CANDIDATO">Candidato</MenuItem>}
            </TextField>
          </Grid>

          {type === 'candidato' && (
            <>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Ciclo de recrutamento"
                  variant="outlined"
                  fullWidth
                  select
                  defaultValue={formData.situacao_empresa}
                  {...register('situacao_empresa')}
                  error={!!errors.situacao_empresa}
                  helperText={errors.situacao_empresa?.message}>
                  <MenuItem value=""></MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Etapa de seleção"
                  variant="outlined"
                  fullWidth
                  select
                  defaultValue={'ANDAMENTO'}
                  {...register('situacao_empresa')}
                  error={!!errors.situacao_empresa}
                  helperText={errors.situacao_empresa?.message}>
                  <MenuItem value="ANDAMENTO">Em andamento</MenuItem>
                  <MenuItem value="APROVADO">Aprovado</MenuItem>
                  <MenuItem value="REPROVADO">Reprovado</MenuItem>
                </TextField>
              </Grid>
            </>
          )}
          {type !== 'candidato' && (
            <>
              {companyBranchs && (
                <Grid item xs={12} md={4}>
                  <TextField
                    label="Tirou férias no último ano?"
                    variant="outlined"
                    fullWidth
                    select
                    defaultValue={formData.ferias_ultimo_ano ? 'true' : 'false'}
                    {...register('ferias_ultimo_ano')}
                    error={!!errors.ferias_ultimo_ano}
                    helperText={errors.ferias_ultimo_ano?.message}>
                    <MenuItem value="true">Sim</MenuItem>
                    <MenuItem value="false">Não</MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <TextField
                  label="Número de faltas no ano"
                  variant="outlined"
                  fullWidth
                  defaultValue={formData.faltas_ano}
                  {...register('faltas_ano')}
                  error={!!errors.faltas_ano}
                  helperText={errors.faltas_ano?.message}
                  key={1}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Número de atestados no ano"
                  variant="outlined"
                  fullWidth
                  defaultValue={formData.atestado_ano}
                  {...register('atestado_ano')}
                  error={!!errors.atestado_ano}
                  helperText={errors.atestado_ano?.message}
                  key={1}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Modelo de contratação"
                  variant="outlined"
                  fullWidth
                  select
                  defaultValue={formData.modelo_contrato || ''}
                  {...register('modelo_contrato')}
                  error={!!errors.modelo_contrato}
                  helperText={errors.modelo_contrato?.message}>
                  <MenuItem value="APRENDIZ">Aprendiz</MenuItem>
                  <MenuItem value="APOSENTADO">Aposentado</MenuItem>
                  <MenuItem value="CLT">CLT</MenuItem>
                  <MenuItem value="ESTAGIARIO">Estagiário</MenuItem>
                  <MenuItem value="PJ">PJ</MenuItem>
                  <MenuItem value="TEMPORARIO">Prazo Determinado</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  label="Data de admissão"
                  variant="outlined"
                  fullWidth
                  defaultValue={formData.data_admissao}
                  InputProps={{
                    inputComponent: DateMaskCustom
                  }}
                  {...register('data_admissao')}
                  error={!!errors.data_admissao}
                  helperText={errors.data_admissao?.message}></TextField>
              </Grid>
            </>
          )}
        </Grid>

        <Typography variant="h4" sx={{ mb: 1, mt: 2 }}>
          Setor
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <TextField
              label="Empresa"
              variant="outlined"
              fullWidth
              select
              defaultValue={
                companyChildren.find((c: any) => c.label === formData.empresa_filha)?.value
              }
              {...register('empresa_filha')}
              onChange={(event: any) => {
                filterBranchs(event.target?.value)
              }}
              error={!!errors.empresa_filha}
              helperText={errors.empresa_filha?.message}>
              {companyChildren.map((item: any, index: number) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </TextField>
            {/* <Controller
            name="empresa_filha"
            control={control}
            render={(props: any) => (
              <Autocomplete
                {...props.field}
                options={companyChildren}
                getOptionLabel={(option: any) => option.label}
                defaultValue={{
                  label: formData.empresa_filha,
                  value: formData.empresa_filha_id
                }}
                disableClearable
                renderInput={(params) => (
                  <TextField {...params} label="Empresas" variant="outlined" />
                )}
                // onChange={(event: any, value: any) => {
                // }}

                isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
              />
            )}
          />*/}
          </Grid>
          {!!filteredBranchs && (
            <Grid item xs={12} md={3}>
              <TextField
                label="Filial"
                variant="outlined"
                fullWidth
                select
                defaultValue={formData.empresa_filial || ''}
                disabled={filteredBranchs.length === 0}
                {...register('empresa_filial')}
                error={!!errors.empresa_filial}
                helperText={errors.empresa_filial?.message}>
                {filteredBranchs.map((item: any, index: number) => {
                  return (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  )
                })}
              </TextField>
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <TextField
              label="Departamento"
              variant="outlined"
              fullWidth
              select
              defaultValue={formData.empresa_setor}
              {...register('empresa_setor')}
              error={!!errors.empresa_setor}
              helperText={errors.empresa_setor?.message}>
              {companySectors.map((item: any, index: number) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              label="Cargo"
              variant="outlined"
              fullWidth
              select
              defaultValue={formData.empresa_cargo}
              {...register('empresa_cargo')}
              error={!!errors.empresa_cargo}
              helperText={errors.empresa_cargo?.message}>
              {companyPositions.map((item: any, index: number) => {
                return (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </TextField>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} sx={{ textAlign: 'center', mt: 2 }}>
          <Button sx={{ px: 5 }} variant="contained" type="submit">
            Salvar
          </Button>
        </Grid>
      </Box>
      <Box
        display={'flex'}
        sx={{ mt: 2 }}
        flexDirection={'column'}
        gap={2}
        component={'form'}
        onSubmit={handleSubmitPass(onSubmitPassword)}>
        <Typography
          sx={{ display: 'flex', alignItems: 'center' }}
          variant="h3"
          color="text.primary">
          Alterar senha
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Nova senha"
              variant="outlined"
              fullWidth
              defaultValue=""
              {...registerPass('senha')}
              error={!!errorsPass.senha}
              helperText={errorsPass.senha?.message}
              key={1}
              type={toggle_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      aria-label="toggle password visibility"
                      onClick={() => setTogglePassword(!toggle_password)}
                      onMouseDown={(event) => event.preventDefault()}>
                      {toggle_password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Confirmar senha"
              variant="outlined"
              fullWidth
              defaultValue=""
              {...registerPass('confirmar_senha')}
              error={!!errorsPass.confirmar_senha}
              helperText={errorsPass.confirmar_senha?.message}
              key={1}
              type={toggle_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      aria-label="toggle password visibility"
                      onClick={() => setTogglePassword(!toggle_password)}
                      onMouseDown={(event) => event.preventDefault()}>
                      {toggle_password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} sx={{ textAlign: 'center', mt: 2 }}>
            <Button sx={{ px: 5 }} variant="contained" type="submit">
              Alterar senha
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
