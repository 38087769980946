import {
  Box,
  Breadcrumbs,
  Typography,
  Grid,
  Button,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Skeleton,
  useTheme,
  ButtonGroup
} from '@mui/material'

import { useState, useContext, forwardRef } from 'react'

import { enqueueSnackbar } from 'notistack'

import { useMutation, useQueryClient, useQuery } from 'react-query'

import InputMask from 'react-input-mask'

import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'
import DeleteIcon from '@mui/icons-material/Delete'

import { useLocation, useNavigate } from 'react-router-dom'

import { AuthContext } from '../../../contexts/auth/AuthContext'
import userBatchServices from '../../../services/userBatchServices'
import { ModalDelete } from '../../../components/common/ModalDelete'

import moment from 'moment'

const DateMaskCustom = forwardRef<HTMLElement, any>(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props
  return (
    <InputMask {...other} mask="99/99/9999" maskChar={null} inputRef={ref} onChange={onChange} />
  )
})

export const UserBatch = () => {
  const auth = useContext(AuthContext)
  const queryClient = useQueryClient()
  const theme = useTheme()

  const location = useLocation()
  const navigate = useNavigate()

  const [showModalDelete, setShowModalDelete] = useState(false)
  const [id, setId] = useState('')

  const { data: batchs, isLoading: batchsLoading } = useQuery('batchs', async () => {
    return await userBatchServices.getAllBatchForList({})
  })

  // const mutationPutUser = useMutation(
  //   async (data: Schema) => {
  //     return await userServices.updateUser(formData?._id || '', data)
  //   },
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries('users')
  //       enqueueSnackbar('Salvo com sucesso!', { variant: 'success' })
  //     },
  //     onError: () => {
  //       enqueueSnackbar('Ocorreu um erro ao salvar!', { variant: 'error' })
  //     }
  //   }
  // )

  const mutationDeleteBatch = useMutation(
    async () => {
      return await userBatchServices.deleteUserBatch(id || '')
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('batchs')
        enqueueSnackbar('Removido com sucesso!', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Ocorreu um erro ao remover!', { variant: 'error' })
      }
    }
  )

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <ModalDelete
        open={showModalDelete}
        onClose={() => setShowModalDelete(false)}
        message={'Deseja realmente remover este lote?'}
        remove={() => mutationDeleteBatch.mutate()}
      />

      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/')
            }}>
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </Link>
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/users')
            }}>
            Usuários
          </Link>
          <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
            Importação em lote
          </Typography>
        </Breadcrumbs>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <Button variant={'text'} size={'small'}>
            Recarregar status
          </Button>
          <Button
            variant={'contained'}
            size={'small'}
            onClick={() => {
              navigate('/users/batch-form')
            }}>
            + Cadastro em lote
          </Button>
        </Box>
      </Box>

      <Typography variant="h3">Importação em lote</Typography>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Cliente</TableCell>
                <TableCell>Situação</TableCell>
                <TableCell>Criado em</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {batchsLoading && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <Box
                      sx={{
                        width: '100%',
                        mb: 1
                      }}>
                      <Skeleton variant={'rectangular'} height={90} width="100%" />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        mb: 1
                      }}>
                      <Skeleton variant={'rectangular'} height={90} width="100%" />
                    </Box>
                    <Box
                      sx={{
                        width: '100%',
                        mb: 1
                      }}>
                      <Skeleton variant={'rectangular'} height={90} width="100%" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {batchs?.map((batch: any) => (
                <TableRow key={batch._id}>
                  <TableCell>
                    <Box
                      display={'flex'}
                      flexDirection={'column'}
                      sx={{
                        mt: 1,
                        alignItems: 'flex-start'
                      }}>
                      {batch?.empresa?.razao}
                    </Box>
                  </TableCell>
                  <TableCell>{batch.situacao}</TableCell>
                  <TableCell>{moment(batch.data_criacao).format('DD/MM/YYYY')}</TableCell>
                  <TableCell>
                    <Box display={'flex'} flexDirection={'row'} gap={1}>
                      <Button color={'primary'} variant={'outlined'} size={'small'}>
                        Detalhes
                      </Button>
                      <IconButton
                        size="small"
                        sx={{ color: `${theme.palette.error.dark}` }}
                        onClick={() => {
                          setId(batch._id)
                          setShowModalDelete(true)
                        }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={0}
          rowsPerPage={5}
          page={0}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
          labelRowsPerPage="Linhas por página"
        />
      </Box>

      <Grid item xs={12} md={12} sx={{ textAlign: 'center', mt: 2 }}>
        <Button sx={{ px: 5 }} variant="contained" type="submit">
          Salvar
        </Button>
      </Grid>
    </Box>
  )
}
