import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import { ChangeEvent, useState } from 'react'
import { CompanyTab } from './CompanyTab'
import { CompanyBranchTab } from './CompanyBranchTab'
import { CompanySectorTab } from './CompanySectorTab'
import { CompanyPositionTab } from './CompanyPositionTab'

export const CompanyRegistration = () => {
  const [currentTab, setCurrentTab] = useState<string>('Empresas')

  const handleTabsChange = (event: ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value)
  }

  return (
    <Box>
      <Typography variant="h3">Cadastros</Typography>

      <Tabs
        sx={{ mt: 2 }}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleTabsChange}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto">
        <Tab label="Empresas" value={'Empresas'} />
        <Tab label="Filiais" value={'Filiais'} />
        <Tab label="Departamentos" value={'Departamentos'} />
        <Tab label="Cargos" value={'Cargos'} />
      </Tabs>

      {currentTab === 'Empresas' && <CompanyTab />}
      {currentTab === 'Filiais' && <CompanyBranchTab />}
      {currentTab === 'Departamentos' && <CompanySectorTab />}
      {currentTab === 'Cargos' && <CompanyPositionTab />}
    </Box>
  )
}
