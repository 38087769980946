import api from './api'

export interface ICompanyService {
  getAllCompany: (params: any) => Promise<any>
}

export const companyService = <ICompanyService>{
  getAllCompany: async (params: any) => {
    try {
      const { data } = await api.get('/auth/empresas', { params })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar empresas')
    }
  }
}
