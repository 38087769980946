import api from './api'

export interface IGroupServices {
  getAll: (params: any) => Promise<any>
}

export const groupServices = () => ({
  getAll: async (params: any) => {
    try {
      const { data } = await api.get('/auth/grupos', { params })
      return data.response
    } catch (e) {
      throw new Error('Erro ao buscar grupos')
    }
  }
})

export default <IGroupServices>groupServices()
