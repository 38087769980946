import {
  Card,
  Typography,
  Box,
  TextField,
  Button,
  useTheme,
  InputAdornment,
  IconButton
} from '@mui/material'
import { useContext, useState } from 'react'
import logo from '../../assets/login/logo.png'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import * as z from 'zod'
import { useMutation } from 'react-query'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { enqueueSnackbar } from 'notistack'

import TwoFactorAuthenticationModal from '../../components/2FAModals/2FAInputModal'

const schema = z.object({
  username: z.string().min(6, 'O nome de usuário deve ter no mínimo 6 caracteres'),
  senha: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
  token: z.string().optional()
})

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })
  const theme = useTheme()
  const navigate = useNavigate()
  const [toggle_password, setTogglePassword] = useState(false)
  const [show2fa, setShow2fa] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  type Schema = z.infer<typeof schema>

  const auth = useContext(AuthContext)

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (data: Schema) => auth.signIn(data.username, data.senha, data.token || ''),
    {
      onSuccess: () => {
        if (auth.user && auth.user['2FA']?.ativo) {
          setShow2fa(true)
        }
      },
      onError: (error: any, data) => {
        console.log(error.message)
        if (error.message === 'Código 2FA não informado!') {
          setShow2fa(true)
          setUsername(data.username)
          setPassword(data.senha)
        } else
          setError('username', {
            type: 'manual',
            message: 'Usuário ou senha invalidos'
          })
      }
    }
  )

  const onSubmit = (data: Schema) => {
    mutate(data)
  }

  const navigateToForgotPassword = () => {
    navigate('/forgot-password')
  }

  const handleClose2FA = (token: string) => {
    try {
      mutate({ username, senha: password, token })
    } catch (err) {
      enqueueSnackbar('Código incorreto!', { variant: 'error' })
    }
  }

  return (
    <>
      <TwoFactorAuthenticationModal open={show2fa} handleClose={handleClose2FA} />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          p: 2
        }}>
        <Box component={'img'} src={logo} sx={{ width: 192 }} />

        <Card sx={{ width: '95%', maxWidth: 546, p: 2, py: 4 }}>
          <Box
            component={'form'}
            display={'flex'}
            flexDirection={'column'}
            gap={2}
            onSubmit={handleSubmit(onSubmit)}>
            <Box>
              <Typography variant={'h5'}>Já é um parceiro da HSPW?</Typography>
              <Typography variant={'body2'}>Entre e veja os dados da sua empresa</Typography>
            </Box>
            <TextField
              fullWidth
              label={'Nome de usuário'}
              variant={'outlined'}
              {...register('username')}
              error={!!errors.username}
              helperText={errors.username?.message}
            />
            <TextField
              variant={'outlined'}
              {...register('senha')}
              error={!!errors.senha}
              helperText={errors.senha?.message}
              label="Senha"
              placeholder="Password"
              fullWidth
              type={toggle_password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      tabIndex={-1}
                      sx={{ color: theme.palette.primary.main }}
                      aria-label="toggle password visibility"
                      onClick={() => setTogglePassword(!toggle_password)}
                      onMouseDown={(event) => event.preventDefault()}>
                      {toggle_password ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Typography
              variant={'body2'}
              sx={{ textAlign: 'right', color: theme.palette.primary.main, cursor: 'pointer' }}
              onClick={navigateToForgotPassword}>
              Esqueceu a senha?
            </Typography>
            <Button fullWidth variant={'contained'} type="submit">
              Entrar
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  )
}
