import {
  Box,
  Breadcrumbs,
  Typography,
  Grid,
  Button,
  TablePagination,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Skeleton,
  useTheme,
  ButtonGroup,
  TextField,
  MenuItem,
  Card
} from '@mui/material'

import { useState, useContext, SetStateAction } from 'react'

import { useMutation, useQueryClient, useQuery } from 'react-query'

import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import * as z from 'zod'
import DeleteIcon from '@mui/icons-material/Delete'
import * as XLSX from 'xlsx'
// import ReactFileReader from 'react-file-reader'

import { useLocation, useNavigate } from 'react-router-dom'

import { AuthContext } from '../../../../contexts/auth/AuthContext'
import userBatchServices from '../../../../services/userBatchServices'

import { useSnackbar } from 'notistack'

export const UserBatchForm = () => {
  const auth = useContext(AuthContext)
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const location = useLocation()
  const navigate = useNavigate()

  // const [id, setId] = useState('')
  // const [cliente, setCliente] = useState('')
  const [users, setUsers] = useState<any[]>([])
  const [errors, setErrors] = useState<any[]>([])
  const [count, setCount] = useState(0)

  const mutationPostBatch = useMutation(
    async () => {
      return await userBatchServices.postUsersBatch({
        empresa: auth.user?.empresa,
        usuarios: users
      })
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Enviado para processamento!', { variant: 'success' })

        setUsers([])
        setErrors([])
        setCount(0)
      },
      onError: () => {
        enqueueSnackbar('Ocorreu um erro ao enviar o lote', { variant: 'error' })
      }
    }
  )

  const validateMobile = (mobile: string) => {
    if (!mobile || mobile === '') return false
    const regex = /(\(?[0]?[1-9][0-9]\)?)(\.|-|\s)?([9]{1})?[1-9][0-9]{3}(\.|-|\s)?[0-9]{4}/g
    return regex.test(String(mobile).toLowerCase())
  }

  const handleUser = (item: any, errors: any, users: any, index: any) => {
    function fieldIsValid(field: string | number) {
      if (Number.isInteger(field)) {
        return true
      }

      if (typeof field === 'string' && field.trim()) {
        return true
      }

      return false
    }

    function isValidEmail(email: string) {
      const re = /\S+@\S+\.\S+/
      return re.test(email)
    }

    let obj = {
      matricula: item[0],
      username: item[1] ?? '',
      senha: item[2] ?? '',
      empresa_filha: item[3],
      empresa_filial: item[4] ? item[4] : '',
      departamento: item[5],
      time: item[6],
      cargo: item[7],
      data_admissao: item[8],
      telefone: item[9],
      situacao_empresa: item[10] ? item[10].trim() : '',
      modelo_contrato: item[11] ? item[11].trim() : '',
      atestado_ano: item[12] ? item[12] : '',
      faltas_ano: item[13] ? item[13] : '',
      ferias_ultimo_ano: item[14] ? item[14] : false,
      data_demissao: item[15] ? item[15] : '',
      data_afastamento: item[16] ? item[16] : '',
      email: item[17] ? item[17].trim() : '',
      linha: index + 1,
      motivo: ''
    }

    if (!fieldIsValid(item[0])) {
      obj.motivo = 'Matrícula não informada!'
      errors.push(obj)
    } else if (fieldIsValid(item[1]) && !fieldIsValid(item[2])) {
      obj.motivo = 'Se preencher usuário, informe a senha!'
      errors.push(obj)
    } else if (!fieldIsValid(item[1]) && fieldIsValid(item[2])) {
      obj.motivo = 'Se preencher senha, informe o usuário!'
      errors.push(obj)
    } else if (!fieldIsValid(item[3])) {
      obj.motivo = 'Empresa não informada!'
      errors.push(obj)
    } else if (!fieldIsValid(item[4])) {
      obj.motivo = 'Filial não informada!'
      errors.push(obj)
    } else if (item[17] && !isValidEmail(item[17])) {
      obj.motivo = 'Email inválido'
      errors.push(obj)
    } else {
      if (!fieldIsValid(item[7])) {
        obj.motivo = 'Cargo não informado!'
        errors.push(obj)
      } else if (!validateDate(item[8])) {
        obj.motivo = 'Data Admissão inválida - Formato = DD/MM/AAAA'
        errors.push(obj)
      } else if (item[15] && !validateDate(item[15])) {
        obj.motivo = 'Data Demissão inválida - Formato = DD/MM/AAAA'
        errors.push(obj)
      } else if (item[16] && !validateDate(item[16])) {
        obj.motivo = 'Data Afastamento inválida - Formato = DD/MM/AAAA'
        errors.push(obj)
      } else if (item[9] && !validateMobile(item[9])) {
        obj.motivo = 'Celular inválido'
        errors.push(obj)
      }
    }

    if (!obj.motivo) {
      obj.telefone = item[9] ? item[9].toString().replace(/[ -()]/g, '') : ''
      users.push(obj)
    }
  }

  const validateDate = (date: string) => {
    if (!date || date === '') return false
    const regex = /(\d{2}\/\d{2}\/\d{4})/g
    return regex.test(String(date).toLowerCase())
  }

  const handlerXls = (file: any) => {
    const users: SetStateAction<any[]> = []
    let erros: SetStateAction<any[]> = []
    let countAux = 0
    // setLoading(true)
    const reader = new FileReader()
    reader.onload = async function () {
      const dados = new Uint8Array(reader.result as any)
      const workbook = XLSX.read(dados, { type: 'array' })
      const first_worksheet = workbook.Sheets[workbook.SheetNames[0]]
      const arr = XLSX.utils.sheet_to_json(first_worksheet, { header: 1 }) as any[]

      for (let i = 1; i < arr.length; i++) {
        // Considerar a primeira linha como sendo o header
        if (arr[i][0] && arr[i][0].toString() !== '') {
          countAux++
          handleUser(arr[i], errors, users, i)
        }
      }

      if (countAux === 0) {
        enqueueSnackbar('Faça upload de um arquivo com pelo menos um usuário!', {
          variant: 'error'
        })
        return
      }

      setCount(countAux)
      setErrors(erros)
      setUsers(users)
    }

    setTimeout(() => {
      reader.readAsArrayBuffer(file)
    }, 250)
  }

  function handleImport() {
    const fileInput = document.getElementById('fileInput') as HTMLInputElement

    if ((fileInput?.files?.length || 0) > 0) {
      const file = fileInput.files ? fileInput.files[0] : null

      if (file) {
        handlerXls(file)
      }
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <input id="fileInput" type="file" style={{ display: 'none' }} onChange={handleImport} />

      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/')
            }}>
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
          </Link>
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/users')
            }}>
            Usuários
          </Link>
          <Link
            underline="hover"
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            color="inherit"
            onClick={() => {
              navigate('/users/batch')
            }}>
            Importação em lote
          </Link>
          <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
            Novo Lote
          </Typography>
        </Breadcrumbs>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <Button variant={'text'} size={'small'}>
            Exemplo .xlsx
          </Button>
          <Button
            variant={'contained'}
            size={'small'}
            onClick={() => {
              const input = document.getElementById('fileInput')
              if (input) {
                input.click()
              }
              handleImport()
            }}>
            Upload de arquivo
          </Button>
        </Box>
      </Box>

      <Typography variant="h3">Importação em lote</Typography>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 171
            }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              sx={{
                my: 2
              }}>
              <Box sx={{ width: 200 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.primary.main,
                    textAlign: 'center'
                  }}>
                  Encontrados:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.primary.main,
                    textAlign: 'center'
                  }}>
                  {count}
                </Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 171
            }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              sx={{
                my: 2
              }}>
              <Box sx={{ width: 200 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.success.dark,
                    textAlign: 'center'
                  }}>
                  Prontos para envio:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.success.dark,
                    textAlign: 'center'
                  }}>
                  {users?.length || 0}
                </Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: 171
            }}>
            <Box
              display={'flex'}
              flexDirection={'row'}
              gap={2}
              sx={{
                my: 2
              }}>
              <Box sx={{ width: 200 }}>
                <Typography
                  variant="h5"
                  sx={{
                    color: theme.palette.error.dark,
                    textAlign: 'center'
                  }}>
                  Com erro:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    color: theme.palette.error.dark,
                    textAlign: 'center'
                  }}>
                  {errors?.length || 0}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>

      {errors?.length > 0 && (
        <Box display={'flex'} flexDirection={'column'} gap={2} sx={{ mt: 2 }}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            sx={{
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.error.dark
              }}>
              <Box
                component="span"
                sx={{
                  color: theme.palette.error.dark,
                  fontWeight: 700
                }}>
                Dados com erro:
              </Box>{' '}
              Corrija o arquivo XLS e envie novamente. Caso queira enviar assim mesmo, os dados
              abaixo serão ignorados
            </Typography>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Linha do XLS</TableCell>
                  <TableCell>Matrícula</TableCell>
                  <TableCell>Motivo</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {errors?.length > 0 &&
                  errors.map((err: any, index) => (
                    <TableRow key={index}>
                      <TableCell>{err.linha}</TableCell>
                      <TableCell>{err.matricula}</TableCell>
                      <TableCell>{err.motivo}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={0}
            rowsPerPage={5}
            page={0}
            onPageChange={() => {}}
            onRowsPerPageChange={() => {}}
            labelRowsPerPage="Linhas por página"
          />
        </Box>
      )}

      <Box display={'flex'} flexDirection={'column'} gap={2} sx={{ mt: 2 }}>
        <Box
          display={'flex'}
          flexDirection={'row'}
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center'
          }}>
          <Typography
            variant="h5"
            sx={{
              color: theme.palette.success.dark
            }}>
            Dados prontos para envio:
          </Typography>
          <Button
            variant={'contained'}
            size={'small'}
            color={'success'}
            disabled={mutationPostBatch.isLoading || !users?.length}
            onClick={() => mutationPostBatch.mutate()}>
            Enviar
          </Button>
        </Box>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Linha#</TableCell>
                <TableCell>Matrícula</TableCell>
                <TableCell>Usuário</TableCell>
                <TableCell>Senha</TableCell>
                <TableCell>Empresa</TableCell>
                <TableCell>Filial</TableCell>
                <TableCell>Departamento</TableCell>
                <TableCell>Cargo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.map((user: any, index) => (
                <TableRow key={index}>
                  <TableCell>{user.linha}</TableCell>
                  <TableCell>{user.matricula}</TableCell>
                  <TableCell>{user.username}</TableCell>
                  <TableCell>{user.senha}</TableCell>
                  <TableCell>{user.empresa_filha}</TableCell>
                  <TableCell>{user.empresa_filial}</TableCell>
                  <TableCell>{user.departamento}</TableCell>
                  <TableCell>{user.cargo}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={0}
          rowsPerPage={5}
          page={0}
          onPageChange={() => {}}
          onRowsPerPageChange={() => {}}
          labelRowsPerPage="Linhas por página"
        />
      </Box>
    </Box>
  )
}
