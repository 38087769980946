import {
  Box,
  Button,
  Card,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  IconButton
} from '@mui/material'
import { useState } from 'react'
import logo from '../../assets/login/logo.png'
import userServices from '../../services/userServices'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useLocation } from 'react-router-dom'

import * as z from 'zod'

const schema = z
  .object({
    senha: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres'),
    confirmar_senha: z.string().min(6, 'A senha deve ter no mínimo 6 caracteres')
  })
  .superRefine(({ senha, confirmar_senha }, ctx) => {
    if (senha !== confirmar_senha) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'As senhas não coincidem',
        path: ['confirmar_senha']
      })
    }
  })

export const RecoverPassword = () => {
  type Schema = z.infer<typeof schema>

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const theme = useTheme()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [toggle_password, setTogglePassword] = useState(false)

  function getQuery() {
    return new URLSearchParams(useLocation().search)
  }

  const query = getQuery()

  const { mutate, isLoading, isSuccess, isError, error } = useMutation(
    (data: Schema) =>
      userServices.postUpdatePasswordByVoucher({ senha: data.senha, voucher: query.get('uv') }),
    {
      onSuccess: () => {
        enqueueSnackbar('Senha alterada com sucesso.', {
          variant: 'success'
        })
        handleBackToLogin()
      },
      onError: (error) => {
        console.log(error)
      }
    }
  )

  const onSubmit = (data: Schema) => {
    mutate(data)
  }

  const handleBackToLogin = () => {
    navigate('/')
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        p: 2
      }}>
      <Box component={'img'} src={logo} sx={{ width: 192 }} />
      <Card sx={{ width: '95%', maxWidth: 546, p: 2, pt: 4 }}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={2}
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}>
          <Box>
            <Typography variant={'h5'}>Vamos alterar sua senha?</Typography>
            <Typography variant={'body2'}>
              Preencha a nova senha e abaixo confime a mesma para alterar.
            </Typography>
          </Box>
          <TextField
            variant={'outlined'}
            {...register('senha')}
            error={!!errors.senha}
            helperText={errors.senha?.message}
            label="Senha"
            placeholder="Password"
            fullWidth
            type={toggle_password ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: theme.palette.primary.main }}
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setTogglePassword(!toggle_password)}
                    onMouseDown={(event) => event.preventDefault()}>
                    {toggle_password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <TextField
            variant={'outlined'}
            {...register('confirmar_senha')}
            error={!!errors.confirmar_senha}
            helperText={errors.confirmar_senha?.message}
            label="Confirmar senha"
            placeholder="Password"
            fullWidth
            type={toggle_password ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ color: theme.palette.primary.main }}
                    tabIndex={-1}
                    aria-label="toggle password visibility"
                    onClick={() => setTogglePassword(!toggle_password)}
                    onMouseDown={(event) => event.preventDefault()}>
                    {toggle_password ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Button fullWidth variant={'contained'} type="submit">
            Alterar senha
          </Button>
          <Typography
            onClick={handleBackToLogin}
            variant={'body2'}
            sx={{ textAlign: 'right', color: theme.palette.primary.main, cursor: 'pointer' }}>
            Voltar para a tela de login
          </Typography>
        </Box>
      </Card>
    </Box>
  )
}
