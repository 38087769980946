import api from './api'
// import { TUser } from '../types/user'

export interface IUserBatchServices {
  getAllBatchForList: (params: any) => Promise<any[]>
  deleteUserBatch: (batchId: string) => Promise<any>
  postUsersBatch: (batch: any) => Promise<any>
}

export const userBatchServices = () => ({
  getAllBatchForList: async (params: any) => {
    try {
      const { data } = await api.get('auth/usuarios-lotes', {
        params
      })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar lotes')
    }
  },
  deleteUserBatch: async (batchId: string) => {
    try {
      const { data } = await api.delete(`auth/usuarios/lotes/${batchId}`)
      return data.response
    } catch (error) {
      throw new Error('Erro ao deletar lote')
    }
  },
  postUsersBatch: async (batch: any) => {
    try {
      const { data } = await api.post('auth/usuarios/lotes', batch)
      return data.response
    } catch (error) {
      throw new Error('Erro ao cadastrar lote')
    }
  }
})

export default <IUserBatchServices>userBatchServices()
