import { useRef, useEffect, KeyboardEvent, useState } from 'react'
import {
  Button,
  Modal,
  TextField,
  Box,
  Typography,
  Skeleton,
  Dialog,
  DialogContent,
  DialogTitle
} from '@mui/material'
import InfoOutlined from '@mui/icons-material/InfoOutlined'

type TwoFactorAuthenticationModalProps = {
  open: boolean
  handleClose: (token: string) => void
}

function TwoFactorAuthenticationModal(props: TwoFactorAuthenticationModalProps) {
  const { open, handleClose } = props
  const inputRefs = useRef<(HTMLDivElement | null)[]>([])
  const [values, setValues] = useState<string[]>(['', '', '', '', '', ''])

  useEffect(() => {
    if (open) {
      inputRefs.current[0]?.focus()
    }
  }, [open])

  const handlePaste = (e: any, index: number) => {
    function isNumber(key: string) {
      return /[0-9]/.test(key)
    }
    const pastedData = e.clipboardData.getData('text/plain')

    if (!isNumber(pastedData)) {
      e.preventDefault()
      return
    }

    const pastedValues = pastedData.split('')
    if (pastedValues.length > 6) {
      e.preventDefault()
      return
    }
    setValues((values) => {
      const newValues = [...values]
      pastedValues.forEach((value: any, i: any) => {
        newValues[index + i] = value
      })
      return newValues
    })
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, index: number) => {
    function isNumber(key: string) {
      return /[0-9]/.test(key)
    }

    if (e.key === 'Backspace') {
      if (index !== 0) {
        inputRefs.current[index - 1]?.focus()
      }

      setValues((values) => {
        const newValues = [...values]
        newValues[index] = ''
        return newValues
      })
    } else if (e.key !== 'Backspace' && index < inputRefs.current.length && isNumber(e.key)) {
      inputRefs.current[index + 1]?.focus()
      setValues((values) => {
        const newValues = [...values]
        newValues[index] = e.key
        return newValues
      })
    } else if (e.key === 'Enter') {
      if (!isValuesFull()) return
      handleSendCode()
    }
  }

  const isValuesFull = () => {
    return values.every((value) => value !== '')
  }

  const handleSendCode = async () => {
    try {
      onClose()
    } catch (error) {
      console.log(error)
    }
  }

  const onClose = (event?: any, reason?: any) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return
    }
    const token = values.join('')

    handleClose(token)
    setValues(['', '', '', '', '', ''])
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      PaperProps={{ style: { overflow: 'visible' } }}>
      <DialogContent>
        <Box
          sx={{
            backgroundColor: '#03A9f4',
            width: 90,
            height: 90,
            borderRadius: 200,
            position: 'absolute',
            top: -45,
            left: 'calc(50% - 45px)',
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <InfoOutlined sx={{ fontSize: 36, color: '#fff' }} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} sx={{ px: 2 }} gap={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5 }}>
            <Typography variant="h4" gutterBottom>
              Autenticação de dois fatores
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2" gutterBottom>
              Abra o aplicativo de autenticação no seu telefone e insira o código de 6 dígitos.
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2 }}>
            {[...Array(6)].map((_, i) => (
              <TextField
                key={i}
                inputRef={(ref) => (inputRefs.current[i] = ref)}
                variant="outlined"
                color="primary"
                size="small"
                type="tel"
                onPaste={(e) => handlePaste(e, i)}
                inputProps={{
                  maxLength: 1,
                  style: {
                    textAlign: 'center',
                    fontSize: '1.2rem',
                    fontWeight: 'bold'
                  }
                }}
                autoFocus={i === 0}
                onKeyDown={(e) => handleKeyDown(e, i)}
                value={values[i]}
                sx={{
                  marginRight: i === 2 ? 3 : 1,
                  width: '2.5rem',
                  height: '2.5rem'
                }}
              />
            ))}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendCode}
              disabled={!isValuesFull()}>
              Enviar
            </Button>
          </Box>
          <Typography variant="body2" gutterBottom sx={{ textAlign: 'center' }}>
            Caso tenha perdido seu celular ou não consiga acessar seu código, fale com o suporte
            HSPW
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default TwoFactorAuthenticationModal
