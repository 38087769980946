import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
  Switch,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  MenuItem,
  TablePagination,
  DialogContentText,
  DialogActions,
  Modal,
  Avatar,
  Skeleton
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useContext, useState } from 'react'
import { companyServices } from '../../services/companyChildServices'
import { companyPositionServices } from '../../services/companyPositionServices'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { useSnackbar } from 'notistack'
import moment from 'moment'

import { CompanyPositionForm } from './CompanyPositionForm'
import groupServices from '../../services/groupServices'

export const CompanyPositionTab = () => {
  const theme = useTheme()
  const auth = useContext(AuthContext)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [openSituationChange, setOpenSituationChange] = useState(false)
  const [item, setItem] = useState<any>(null)
  const [formData, setFormData] = useState<any>(null)

  const { data, isLoading } = useQuery('companyPosition', async () => {
    return await companyPositionServices.getAllCompanyPosition({
      empresa: auth.user?.empresa,
      removido: false
    })
  })

  const { data: groups } = useQuery('familyPositions', async () => {
    return await groupServices.getAll({
      empresa: auth.user?.empresa,
      tipo: 'FAMILIA_CARGOS',
      situacao: 'ATIVO',
      removido: false
    })
  })

  const mutationSituation = useMutation(
    async () => {
      return await companyPositionServices
        .putCompanyPosition({
          nome: item.nome,
          grupo: item.grupo,
          empresa: auth.user?.empresa,
          id: item._id,
          situacao: item.situacao === 'ATIVO' ? 'INATIVO' : 'ATIVO'
        })
        .then(() => {
          queryClient.invalidateQueries('companyPosition')
        })
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Situação alterada com sucesso!', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Erro ao alterar situação!', { variant: 'error' })
      }
    }
  )

  const handleEditRow = (company: any) => {
    setFormData(company)
    setOpen(true)
  }

  isLoading && <Typography variant="h4">Carregando...</Typography>

  const SituationDialog = () => {
    return (
      <Dialog open={openSituationChange} maxWidth="md">
        <DialogTitle variant="h6" sx={{ mt: 2 }}>
          Confirme sua ação
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1">
            Deseja mesmo {item.situacao === 'ATIVO' ? 'inativar' : 'ativar'} a empresa {item.nome} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenSituationChange(false)
            }}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              mutationSituation.mutate()
              setOpenSituationChange(false)
            }}
            color={'warning'}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  const getFamily = (id: string) => {
    const family = groups?.find((group: { _id: string; nome: string }) => group._id === id)
    return family?.nome
  }

  return (
    <Box>
      {openSituationChange && <SituationDialog />}
      {/* {showModal && (
        <ModalDelete
          open={showModal}
          onClose={() => setShowModal(false)}
          empresa={item.nome}
          mutationDelete={mutationDelete}
        />
      )} */}
      {open && <CompanyPositionForm formData={formData} setOpen={setOpen} />}

      <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
        Cargos
      </Typography>

      <Button
        variant="contained"
        size="small"
        sx={{ backgroundColor: '#9C27B0' }}
        startIcon={<AddIcon />}
        onClick={() => {
          setFormData(null)
          setOpen(true)
        }}>
        Novo cargo
      </Button>
      <Button
        disabled
        variant="outlined"
        size="small"
        sx={{
          color: '#9C27B0',
          borderColor: '#9C27B0',
          ml: 2,
          '&:hover': { color: `${theme.palette.primary.main}` }
        }}>
        Lotes
      </Button>

      <TableContainer sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cargo</TableCell>
              <TableCell>Família do cargo</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>data de criação</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Box sx={{ mb: 1 }}>
                    <Skeleton variant="rectangular" height={60} width="100%" />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Skeleton variant="rectangular" height={60} width="100%" />
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Skeleton variant="rectangular" height={60} width="100%" />
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {data?.map((company: any) => (
              <TableRow key={company._id}>
                <TableCell>{company.nome}</TableCell>
                <TableCell>{getFamily(company.grupo)}</TableCell>
                <TableCell>
                  <Switch
                    onChange={() => {
                      setItem(company)
                      setOpenSituationChange(true)
                    }}
                    checked={company.situacao === 'ATIVO'}
                  />
                </TableCell>
                <TableCell>
                  {company.data_criacao
                    ? moment(company.data_criacao).format('DD/MM/YYYY HH:mm')
                    : ''}
                </TableCell>
                <TableCell>
                  <Tooltip title="Editar" placement="top" arrow>
                    <IconButton
                      size="small"
                      sx={{ color: `${theme.palette.primary.main}` }}
                      onClick={() => {
                        handleEditRow(company)
                      }}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
