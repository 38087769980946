import {
  Avatar,
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { useState } from 'react'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { TDiagnosticsData } from '../../../types/diagnostics'
import EmptyIndicator from '../../../components/EmptyIndicator'
interface RankingProps {
  data: TDiagnosticsData[]
  loading: boolean
  title: string
  subheader: string
  emptyText?: string
}

export const Ranking = (props: RankingProps) => {
  const theme = useTheme()
  const [isExpand, setIsExpand] = useState(false)

  const AvatarWrapperSuccess = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.success.lighter};
        color:  ${theme.colors.success.main};
        width: 40px;
        height: 40px;
  `
  )

  const AvatarWrapperError = styled(Avatar)(
    ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color:  ${theme.colors.error.main};
      width: 40px;
      height: 40px;
`
  )

  const AvatarWrapperWarning = styled(Avatar)(
    ({ theme }) => `
        background-color: ${theme.colors.warning.lighter};
        color:  ${theme.colors.warning.main};
        width: 40px;
        height: 40px;
  `
  )

  const Header = () => {
    return (
      <CardHeader
        title={props.title}
        subheader={props.subheader}
        action={
          <IconButton
            aria-label="settings"
            onClick={() => {
              setIsExpand(!isExpand)
            }}
            sx={{
              border: '1px solid',
              borderColor: theme.palette.primary.main,
              borderRadius: 1,
              height: 30
            }}>
            <Typography sx={{ color: theme.palette.primary.main, fontWeight: 700 }}>
              Todos
            </Typography>
            {!isExpand ? (
              <ExpandMoreIcon sx={{ color: theme.palette.primary.main }} />
            ) : (
              <ExpandLessIcon sx={{ color: theme.palette.primary.main }} />
            )}
          </IconButton>
        }
      />
    )
  }

  const CardItem = ({ total, variacao_ranking, titulo }: TDiagnosticsData) => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            textAlign: 'left',
            gap: 1,
            my: 2
          }}>
          {variacao_ranking === 'SUBIU' ? (
            <AvatarWrapperSuccess>
              <ArrowUpwardIcon sx={{ fontSize: 25 }} />
            </AvatarWrapperSuccess>
          ) : variacao_ranking === 'DESCEU' ? (
            <AvatarWrapperError>
              <ArrowDownwardIcon sx={{ fontSize: 25 }} />
            </AvatarWrapperError>
          ) : (
            <AvatarWrapperWarning>
              <ArrowForwardIcon sx={{ fontSize: 25 }} />
            </AvatarWrapperWarning>
          )}
          <Typography variant="h5" sx={{ width: '53%' }}>
            {titulo}
          </Typography>
          <Typography variant="subtitle1" sx={{ width: '100%' }}>
            {total} resultados moderados ou graves
          </Typography>
        </Box>
        <Divider />
      </>
    )
  }

  return (
    <Card sx={{ width: 466 }}>
      <Header />
      <Divider />

      {props?.data && props?.data?.length <= 0 ? (
        <EmptyIndicator text={props.emptyText} />
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              m: 2
            }}>
            <Typography variant="h3">{props.data[0]?.titulo}</Typography>
            <Typography variant="subtitle1">
              {props.data[0]?.total} resultados moderados ou graves
            </Typography>
          </Box>
          <Divider />
          <List sx={{ maxHeight: !isExpand ? 220 : 'none', overflow: 'auto' }}>
            {props.data.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    width: '100%'
                  }}>
                  <CardItem
                    titulo={item.titulo}
                    total={item.total}
                    variacao_ranking={item.variacao_ranking}
                  />
                </Box>
              )
            })}
          </List>
        </>
      )}
    </Card>
  )
}
