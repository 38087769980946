import { Card, Skeleton, Typography } from '@mui/material'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import { TUsersChartData } from '../../../types/user'

interface SmallChartProps {
  title: string
  data: number[]
  loading: boolean
  color: string
}

export const SmallChart = (props: SmallChartProps) => {
  const { title, data, loading, color } = props
  const chart2Options: ApexOptions = {
    chart: {
      background: 'transparent',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      zoom: {
        enabled: false
      }
    },
    fill: {
      gradient: {
        opacityFrom: 0,
        opacityTo: 0
      }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    colors: [color],
    tooltip: {
      theme: 'dark'
    }
  }

  const chart1Data = [
    {
      name: title,
      data: data
    }
  ]

  const skeleton = (
    <>
      <Skeleton height={25} width={38} />
      <Skeleton height={25} width={150} />
      {/* <Skeleton height={65} width={230} variant="rectangular" /> */}
    </>
  )

  return (
    <Card sx={{ p: 2, borderBottom: `10px solid ${color}`, width: 245, height: 80 }}>
      {loading ? (
        skeleton
      ) : (
        <>
          <Typography variant="h2">{data[data.length - 1]}</Typography>
          <Typography pb={2} variant="caption">
            {title}
          </Typography>
          {/* <Chart options={chart2Options} series={chart1Data} type="area" height={60} width="100%" /> */}
        </>
      )}
    </Card>
  )
}
