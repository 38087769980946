import { useState, useEffect, useContext } from 'react'

import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  ButtonGroup,
  Button,
  TextField,
  IconButton,
  useTheme,
  Skeleton
} from '@mui/material'

import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../contexts/auth/AuthContext'

import { companyService } from '../../services/companyServices'
import userServices from '../../services/userServices'
import { ModalDelete } from '../../components/common/ModalDelete'
import { FilterModal } from './FilterModal'

import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useSnackbar } from 'notistack'

import { useQuery, useMutation, useQueryClient } from 'react-query'

export const Users = () => {
  const filterCache = JSON.parse(localStorage.getItem('userFilter') || '{}')

  const [userId, setUserId] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [users, setUsers] = useState([])
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(25)
  const [totalDocs, setTotalDocs] = useState(0)
  const [searchText, setSearchText] = useState('')

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const theme = useTheme()

  const auth = useContext(AuthContext)
  const queryClient = useQueryClient()

  const colorsSituacao = {
    ATIVO: theme.palette.success.dark,
    INATIVO: theme.palette.error.dark,
    RASCUNHO: theme.palette.warning.main
  }

  const textSituacao = {
    ATIVO: 'Ativo',
    INATIVO: 'Inativo',
    RASCUNHO: 'Aguard. 1º acesso'
  }

  const { data: usersData, isLoading: usersLoading } = useQuery(
    'users',
    async () => {
      return await userServices.getAllUsersList({
        empresa: auth.user?.empresa,
        page: 1,
        limit: limit,
        ...filterCache
      })
    },
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setPage(data.page - 1)
        setTotalDocs(data.totalDocs)
        setUsers(data.docs || [])
      }
    }
  )

  const { data: company, isLoading: companyLoading } = useQuery('company', async () => {
    return await companyService.getAllCompany({ _id: auth.user?.empresa })
  })

  const mutationDelete = useMutation(
    async () => {
      return await userServices.deleteUser(userId || '').then(() => {
        queryClient.invalidateQueries('users')
      })
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Usuário removido com sucesso!', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Erro ao remover usuário!', { variant: 'error' })
      }
    }
  )

  const mutationSearch = useMutation(
    async (filter: any) => {
      return await userServices.getAllUsersList({
        empresa: auth.user?.empresa,
        ...filterCache,
        ...filter
      })
    },
    {
      onSuccess: (data) => {
        setPage(data.page - 1)
        setTotalDocs(data.totalDocs)
        setUsers(data.docs || [])
      },
      onError: () => {
        enqueueSnackbar('Erro ao buscar usuários!', { variant: 'error' })
      }
    }
  )

  return (
    <Box display={'flex'} flexDirection={'column'} gap={10}>
      <ModalDelete
        open={showModal}
        onClose={() => setShowModal(false)}
        message={'Deseja realmente remover este usuário?'}
        remove={() => mutationDelete.mutate()}
      />
      <FilterModal
        open={showFilter}
        onClose={() => setShowFilter(false)}
        onFilter={(filter) => {
          mutationSearch.mutate(filter)
        }}
      />

      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} gap={2}>
        <Typography variant="h3">Usuários</Typography>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <ButtonGroup variant="text" aria-label="text secondary button group" color="secondary">
            <Button
              onClick={() => {
                navigate('/users/batch', { state: { operation: 'cad' } })
              }}>
              Lotes
            </Button>
            <Button>Pré-cadastro</Button>
          </ButtonGroup>
          <ButtonGroup size="small" variant="outlined" aria-label="small outlined button group">
            <Button
              onClick={() => {
                navigate('/users/register', { state: { operation: 'cad', type: 'user' } })
              }}>
              Novo usuário
            </Button>
            <Button
              onClick={() => {
                navigate('/users/register', { state: { operation: 'cad', type: 'candidato' } })
              }}>
              Novo candidato
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <Box display={'flex'} flexDirection={'column'} gap={2}>
        <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={1}>
          <TextField
            id="outlined-basic"
            label="Pesquise por matrícula, usuário ou número de telefone..."
            variant="outlined"
            sx={{ flex: 1 }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault()
                mutationSearch.mutate({ matricula: searchText })
              }
            }}
          />
          <Button size="small" variant="outlined" onClick={() => setShowFilter(true)}>
            Filtros
          </Button>
          {/* <Button size="small" variant="contained">
            Gerar relatório XLS
          </Button> */}
        </Box>

        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Usuário / Matrícula</TableCell>
                <TableCell>Telefone</TableCell>
                <TableCell>Departamento</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersLoading || mutationSearch.isLoading ? (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Box sx={{ mb: 1 }}>
                      <Skeleton variant="rectangular" height={60} width="100%" />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Skeleton variant="rectangular" height={60} width="100%" />
                    </Box>
                    <Box sx={{ mb: 1 }}>
                      <Skeleton variant="rectangular" height={60} width="100%" />
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {users?.map((user: any) => (
                    <TableRow key={user._id}>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.telefone || ''}</TableCell>
                      <TableCell>{user.departamento || ''}</TableCell>
                      <TableCell
                        sx={{
                          fontWeight: 'bold',
                          color: colorsSituacao[user.situacao as keyof typeof colorsSituacao],
                          textAlign: 'center'
                        }}>
                        {textSituacao[user.situacao as keyof typeof textSituacao]}
                      </TableCell>
                      <TableCell>
                        <Tooltip title="Editar" placement="top" arrow>
                          <IconButton
                            size="small"
                            sx={{ color: `${theme.palette.primary.main}` }}
                            onClick={() => {
                              navigate('/users/register', {
                                state: { data: user, operation: 'edit' }
                              })
                            }}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Deletar" placement="top" arrow>
                          <IconButton
                            size="small"
                            sx={{ color: `${theme.palette.error.dark}` }}
                            onClick={() => {
                              setUserId(user._id)
                              setShowModal(true)
                            }}>
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={totalDocs || 0}
          rowsPerPage={limit || 25}
          page={page || 0}
          onPageChange={(e, page) => {
            mutationSearch.mutate({ page: page + 1, limit: limit })
          }}
          onRowsPerPageChange={(e) => {
            setLimit(parseInt(e.target.value, 10))
            mutationSearch.mutate({ page: page + 1, limit: parseInt(e.target.value, 10) })
          }}
          labelRowsPerPage="Linhas por página"
        />
      </Box>
    </Box>
  )
}
