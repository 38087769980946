import {
  Dialog,
  DialogTitle,
  Box,
  Card,
  Avatar,
  Grid,
  Button,
  TextField,
  MenuItem,
  DialogContent,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Typography
} from '@mui/material'
import { useState, ChangeEvent, useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { useMutation, useQueryClient, useQuery } from 'react-query'
import { companyService } from '../../services/companyServices'
import { companyBranchServices } from '../../services/companyBranchServices'
import { companyServices } from '../../services/companyChildServices'
import { companySectorServices } from '../../services/companySectorServices'
import { companyPositionServices } from '../../services/companyPositionServices'
import { AuthContext } from '../../contexts/auth/AuthContext'

type FilterModalProps = {
  open: boolean
  onClose: () => void
  onFilter: (filter: any) => void
}

export const FilterModal = (props: FilterModalProps) => {
  const auth = useContext(AuthContext)
  const filterCache = JSON.parse(localStorage.getItem('userFilter') || '{}')

  const { open, onClose, onFilter } = props
  const [empresa, setEmpresa] = useState(filterCache?.empresa_filha || '')
  const [departamento, setDepartamento] = useState(filterCache?.empresa_setor || '')
  const [filial, setFilial] = useState(filterCache?.empresa_filial || '')
  const [cargo, setCargo] = useState(filterCache?.empresa_cargo || '')
  const [status, setStatus] = useState(filterCache?.situacao || '')
  const [situacaoEmpresa, setSituacaoEmpresa] = useState(filterCache?.situacao_empresa || '')

  const { data: companiesChild, isLoading: companyChildLoading } = useQuery(
    'companiesChild',
    async () => {
      return await companyServices.getAllCompaniesChild({
        empresa: auth.user?.empresa
      })
    }
  )

  const { data: companiesBranch, isLoading: companyBranchLoading } = useQuery(
    ['companiesBranch', { empresa: empresa }],
    async () => {
      return await companyBranchServices.getAllCompanyBranch({
        empresa: auth.user?.empresa,
        empresa_filha: empresa
      })
    },
    {
      enabled: !!empresa
    }
  )

  const { data: companiesSector, isLoading: companySectorLoading } = useQuery(
    'companiesSector',
    async () => {
      return await companySectorServices.getAllCompanySector({
        empresa: auth.user?.empresa
      })
    }
  )

  const { data: companiesPosition, isLoading: companyPositionLoading } = useQuery(
    'companiesPosition',
    async () => {
      return await companyPositionServices.getAllCompanyPosition({
        empresa: auth.user?.empresa
      })
    }
  )

  const [tipoAcesso, setTipoAcesso] = useState({
    adminEmpresa: filterCache?.acessos?.includes('AE'),
    adminCadastro: filterCache?.acessos?.includes('AC'),
    usuarioComum: filterCache?.acessos?.includes('UC'),
    familiar: filterCache?.acessos?.includes('DEP')
  })

  const handleTipoAcessoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTipoAcesso({
      ...tipoAcesso,
      [event.target.name]: event.target.checked
    })
  }

  const handleFilter = () => {
    const acessos = []

    if (tipoAcesso.adminEmpresa) acessos.push('AE')
    if (tipoAcesso.adminCadastro) acessos.push('AC')
    if (tipoAcesso.usuarioComum) acessos.push('UC')
    if (tipoAcesso.familiar) acessos.push('DEP')

    const filter = {
      ...(empresa && { empresa_filha: empresa }),
      ...(departamento && { empresa_setor: departamento }),
      ...(filial && { empresa_filial: filial }),
      ...(cargo && { empresa_cargo: cargo }),
      ...(status && { situacao: status }),
      ...(situacaoEmpresa && { situacao_empresa: situacaoEmpresa }),
      acessos
    }
    localStorage.setItem('userFilter', JSON.stringify(filter))

    onFilter(filter)

    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Definir Filtros</DialogTitle>
      <DialogContent>
        <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
          Área de atuação
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Empresa"
                variant="outlined"
                fullWidth
                select
                onChange={(e) => setEmpresa(e.target.value)}
                value={empresa}>
                <MenuItem value="">Nenhuma</MenuItem>

                {companiesChild?.map((company: any) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.razao_social}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Departamento"
                variant="outlined"
                fullWidth
                select
                onChange={(e) => setDepartamento(e.target.value)}
                value={departamento}>
                <MenuItem value="">Nenhum</MenuItem>

                {companiesSector?.map((company: any) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.nome}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Filial"
                variant="outlined"
                fullWidth
                select
                onChange={(e) => setFilial(e.target.value)}
                value={filial}>
                <MenuItem value="">Nenhuma</MenuItem>

                {companiesBranch?.map((company: any) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.nome}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Cargo"
                variant="outlined"
                fullWidth
                select
                onChange={(e) => setCargo(e.target.value)}
                value={cargo}>
                <MenuItem value="">Nenhum</MenuItem>

                {companiesPosition?.map((company: any) => (
                  <MenuItem key={company._id} value={company._id}>
                    {company.nome}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
          Situação do usuário
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Status"
                variant="outlined"
                fullWidth
                select
                onChange={(e) => setStatus(e.target.value)}
                value={status}>
                <MenuItem value="">Nenhum</MenuItem>
                <MenuItem value="ATIVO">Ativo</MenuItem>
                <MenuItem value="INATIVO">Inativo</MenuItem>
                <MenuItem value="RASCUNHO">Aguard. 1º acesso</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <TextField
                label="Situação empresa"
                variant="outlined"
                fullWidth
                select
                onChange={(e) => setSituacaoEmpresa(e.target.value)}
                value={situacaoEmpresa}>
                <MenuItem value="">Nenhuma</MenuItem>
                <MenuItem value="NORMAL">Normal</MenuItem>
                <MenuItem value="AFASTADO_TEMP">Afastado Temp.</MenuItem>
                <MenuItem value="FERIAS">Férias</MenuItem>
                <MenuItem value="DESLIGADO">Desligado</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
          Tipo de acesso
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tipoAcesso.usuarioComum}
                  onChange={handleTipoAcessoChange}
                  name="usuarioComum"
                />
              }
              label="Usuário comum"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tipoAcesso.adminEmpresa}
                  onChange={handleTipoAcessoChange}
                  name="adminEmpresa"
                />
              }
              label="Admin empresa"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tipoAcesso.adminCadastro}
                  onChange={handleTipoAcessoChange}
                  name="adminCadastro"
                />
              }
              label="Admin cadastro"
            />
          </Grid>
        </Grid>
        <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
          Outros filtros
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={tipoAcesso.familiar}
                  onChange={handleTipoAcessoChange}
                  name="familiar"
                />
              }
              label="Usuário com familiar"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button onClick={handleFilter} variant="contained" color="primary">
          Buscar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
