import { useState, ChangeEvent } from 'react'
import { Box, Modal, Typography, Button, CircularProgress } from '@mui/material'

type UploadModalProps = {
  open: boolean
  onClose: () => void
  onUpload: (file: File) => void
}

export const UploadModal = ({ open, onClose, onUpload }: UploadModalProps) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0]
    setSelectedFile(file)
  }

  const handleUpload = async () => {
    try {
      if (selectedFile) {
        setLoading(true)

        await onUpload(selectedFile)
        setSelectedFile(null)
        onClose()
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4
        }}>
        <Typography variant="h6" gutterBottom>
          Upload de nova foto de perfil
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {selectedFile && (
            <Box sx={{ mb: 2 }}>
              <img
                src={URL.createObjectURL(selectedFile)}
                alt="Nova foto de perfil"
                style={{ maxWidth: 300 }}
              />
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <input
              accept="image/png"
              id="icon-button-file"
              type="file"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <label htmlFor="icon-button-file">
              <Button variant="contained" component="span">
                Escolher arquivo
              </Button>
            </label>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button onClick={onClose}>Cancelar</Button>
              <Button
                variant="contained"
                disabled={!selectedFile || loading}
                onClick={handleUpload}
                sx={{ ml: 2 }}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Enviar'}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}
