import { ReactNode, useEffect, useState } from 'react'
import { TUser } from '../../types/user'
import { AuthContext } from './AuthContext'
import userServices from '../../services/userServices'

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<TUser | null>(null)
  const [token, setToken] = useState<string | null>(null)

  useEffect(() => {
    const localToken = localStorage.getItem('@token')
    const localUser = localStorage.getItem('@user')
    if (localToken) {
      setToken(localToken)
    }
    if (localUser) {
      setUser(JSON.parse(localUser))
    }
  }, [])

  const signIn = async (email: string, password: string, token2FA: string) => {
    const response = await userServices.signIn(email, password, token2FA)

    if (response.user) {
      setUser(response.user)
      localStorage.setItem('@user', JSON.stringify(response.user))
      localStorage.removeItem('@helpRequestFilter')
      localStorage.removeItem('@userFilter')
    }

    if (response.access_token) {
      setToken(response.access_token)
      localStorage.setItem('@token', response.access_token)
      return true
    }

    return false
  }

  const signOut = async () => {
    // await api.signOut()
    localStorage.removeItem('@token')
    localStorage.removeItem('@user')
    localStorage.removeItem('@helpRequestFilter')
    localStorage.removeItem('@userFilter')

    setUser(null)
    setToken(null)
  }

  const updateUser = async (user: TUser) => {
    const response = await userServices.updateUser(user.id || '', user)
    if (response) {
      setUser(user)
      localStorage.setItem('@user', JSON.stringify(user))
    }
  }

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, signed: !!token, updateUser }}>
      {children}
    </AuthContext.Provider>
  )
}
