import api from './api'

export interface IcompanyServices {
  getAllCompaniesChild: (params: any) => Promise<any>
  putCompanyChild: (params: any) => Promise<any>
  postCompanyChild: (params: any) => Promise<any>
  deleteCompanyChild: (params: any) => Promise<any>
}

export const companyServices = <IcompanyServices>{
  getAllCompaniesChild: async (params: any) => {
    try {
      const { data } = await api.get('/auth/empresa-filhas', { params })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar empresas')
    }
  },
  putCompanyChild: async (params: any) => {
    try {
      const { data } = await api.put(`/auth/empresa-filhas/${params.empresa_filha}`, params)
      return data.response
    } catch (error: any) {
      throw new Error('Erro ao alterar empresa')
    }
  },
  postCompanyChild: async (params: any) => {
    try {
      const { data } = await api.post('/auth/empresa-filhas', params)
      return data.response
    } catch (error: any) {
      throw new Error('Erro ao cadastrar empresa')
    }
  },
  deleteCompanyChild: async (params: any) => {
    try {
      const { data } = await api.delete(`/auth/empresa-filhas/${params.empresa_filha}`)
      return data.response
    } catch (error: any) {
      throw new Error('Erro ao excluir empresa')
    }
  }
}
