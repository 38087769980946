import { Routes, Route } from 'react-router-dom'
import { BaseLayout } from '../layout/BaseLayout'
import { Sidebar } from '../components/Sidebar'
import { Home } from '../pages/Home'
import { Navigate } from 'react-router-dom'
import { CompanyRegistration } from '../pages/CompanyRegistration'
import { Users } from '../pages/Users'
import { UserRegistration } from '../pages/Users/UserRegistration'
import { UserBatch } from '../pages/Users/UserBatch'
import { UserBatchForm } from '../pages/Users/UserBatch/UserBatchForm'
import { HelpRequests } from '../pages/HelpRequests'
import { HelpRequestEdit } from '../pages/HelpRequests/HelpRequestEdit'
import { ChatDetails } from '../pages/HelpRequests/Chat'
import { PersonalData } from '../pages/PersonalData'
import { Reports } from '../pages/Reports'
import { CompanyChildBatch } from '../pages/CompanyRegistration/CompanyBatch'
import { CompanyChildBatchForm } from '../pages/CompanyRegistration/CompanyBatch/CompanyBatchForm'

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Sidebar />}>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/company-registration" element={<CompanyRegistration />} />
        <Route path="/users" element={<Users />} />
        <Route path="/users/register" element={<UserRegistration />} />
        <Route path="/users/batch" element={<UserBatch />} />
        <Route path="/users/batch-form" element={<UserBatchForm />} />
        <Route path="/help-requests" element={<HelpRequests />} />
        <Route path="/help-requests/edit" element={<HelpRequestEdit />} />
        <Route path="/help-requests/chat" element={<ChatDetails />} />
        <Route path="/personal-data" element={<PersonalData />} />
        <Route path="/reports" element={<Reports />} />
        <Route path="/company-registration/company-batch" element={<CompanyChildBatch />} />
        <Route
          path="/company-registration/company-batch-form"
          element={<CompanyChildBatchForm />}
        />
      </Route>
    </Routes>
  )
}
