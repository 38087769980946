import api from './api'

export interface IHelpRequestsService {
  getAllHelpRequests: (params: any) => Promise<any>
  getOneHelpRequests: (id: any, params?: any) => Promise<any>
  getAllHelpRequestsAttendance: (id: any) => Promise<any>
  getAllHelpRequestsContact: (id: any) => Promise<any>
  getAllHelpRequestsMonitoring: (id: any) => Promise<any>
  putHelpRequest: (id: any, params: any) => Promise<any>
}

export const helpRequestsServices = <IHelpRequestsService>{
  getAllHelpRequests: async (params: any) => {
    try {
      const { data } = await api.get('/auth/pedidos-ajuda', { params })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar pedidos de ajuda')
    }
  },
  getOneHelpRequests: async (id: string, params?: any) => {
    try {
      const { data } = await api.get(`/auth/pedidos-ajuda/${id}`, { params })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar pedido de ajuda')
    }
  },
  getAllHelpRequestsAttendance: async (id: string) => {
    try {
      const { data } = await api.get(`/auth/pedidos-ajuda-atendimentos/pedido-ajuda/${id}`)
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar atendimentos do pedido de ajuda')
    }
  },
  getAllHelpRequestsContact: async (id: string) => {
    try {
      const { data } = await api.get(`/auth/pedidos-ajuda-contatos/pedido-ajuda/${id}`)
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar contatos do pedido de ajuda')
    }
  },
  getAllHelpRequestsMonitoring: async (id: string) => {
    try {
      const { data } = await api.get(`/auth/pedidos-ajuda-acompanhamentos/pedido-ajuda/${id}`)
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar acompanhamentos do pedido de ajuda')
    }
  },
  putHelpRequest: async (id: string, params: any) => {
    try {
      const { data } = await api.put(`/auth/pedidos-ajuda/${id}`, params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao atualizar pedido de ajuda')
    }
  }
}
