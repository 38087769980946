import { useEffect, useState, useContext, forwardRef } from 'react'
import { Box, Breadcrumbs, Button, Typography } from '@mui/material'
import Link from '@mui/material/Link'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { helpRequestsServices } from '../../../services/helpRequestsServices'
import { chatServices } from '../../../services/chatServices'
import { io } from 'socket.io-client'
import process from 'process'

import { Chat } from '../../../components/Chat'

import { AuthContext } from '../../../contexts/auth/AuthContext'

export const ChatDetails = () => {
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const auth = useContext(AuthContext)
  const token = localStorage.getItem('@token')

  const location = useLocation()
  const { chat } = location.state || { chat: null }

  const [helpRequest, setHelpRequest] = useState([])
  const [chatId, setChatId] = useState('')
  const [messages, setMessages] = useState<any>([])
  const [clientSocket, setClientSocket] = useState<any>(null)
  const [finalized, setFinalized] = useState(true)

  const { data: helpData, isLoading: helpLoading } = useQuery(
    'getOneHelpRequests',
    async () => {
      return await helpRequestsServices.getOneHelpRequests(chat)
    },
    {
      onSuccess: (data) => {
        setHelpRequest(data.docs || [])
      },
      onError: () => {
        enqueueSnackbar('Erro ao buscar pedidos de ajuda!', { variant: 'error' })
      }
    }
  )
  const { data: chatInfo } = useQuery(
    'getChatId',
    async () => {
      return await chatServices.getChatHelpRequest({ pedido_ajuda: chat })
    },
    {
      onSuccess: (data) => {
        if (data[0] && data[0].chat) {
          setChatId(data[0].chat || '')
          getMessages(data[0].chat)
        }
      },
      onError: () => {
        enqueueSnackbar('Erro ao buscar mensagens!', { variant: 'error' })
      }
    }
  )
  const getMessages = async (id: any) => {
    try {
      const data = await chatServices.getChatById(id)
      setMessages(data)
      setFinalized(data?.finalizado)
    } catch (error) {
      enqueueSnackbar('Erro ao buscar mensagens!', { variant: 'error' })
    }
  }

  useEffect(() => {
    const url: string = process.env.REACT_APP_API_URL || ''
    const client = io(url, {
      extraHeaders: {
        authorization: `Bearer ${token}`
      }
    })

    setClientSocket(client)
  }, [chat])

  useEffect(() => {
    if (clientSocket) {
      const socketObject = {
        chat: chatId,
        user: auth.user?.id
      }
      // dispara evento para o socket com nova mensagem
      clientSocket.emit('sendMessage', socketObject)
      clientSocket.on('receivedMessage', () => {
        chatId && getMessages(chatId)
      })
      return () => clientSocket.off('receivedMessage')
    }
  }, [chatId, clientSocket])

  const sendMessage = async (text: string) => {
    const dateNotification = new Date()
    const data = {
      usuario: helpData?.usuario?._id,
      data_agendamento: dateNotification,
      tipo: 'PEDIDO_AJUDA',
      titulo: 'Nova mensagem',
      mensagem: 'Você recebeu uma nova mensagem sobre seu pedido de ajuda.',
      chat: chatId,
      recorrente: false
    }
    if (chatId) {
      try {
        const response = await chatServices.sendNewMessage(chatId, {
          mensagem: text,
          sender: 'admin',
          notification: true,
          notification_data: data
        })
        if (response && response.update === 1) {
          const socketObject = {
            chat: chatId,
            user: auth.user?.id
          }
          // dispara evento para o socket com nova mensagem
          clientSocket.emit('sendMessage', socketObject)
          getMessages(chatId)
        }
      } catch (error) {
        console.error(error)
      }
    } else {
      const formData = {
        usuario: helpData?.usuario?._id,
        pedido_ajuda: chat,
        mensagem: text,
        sender: 'admin',
        notification: true,
        notification_data: data
      }
      try {
        const response = await chatServices.createChat(formData)
        if (response) {
          setChatId(response.chat)
          getMessages(response.chat)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const finalizeChat = async () => {
    try {
      const response = await chatServices.putOneChat(chatId, {
        usuario: helpData?.usuario?._id,
        finalizado: true
      })
      if (response && response.update === 1) {
        enqueueSnackbar('Chat finalizado!', { variant: 'success' })

        getMessages(chatId)
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          color="inherit"
          onClick={() => {
            navigate('/')
          }}>
          <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>
        <Link
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          color="inherit"
          onClick={() => {
            navigate('/help-requests')
          }}>
          Pedidos de ajuda
        </Link>
        <Typography sx={{ display: 'flex', alignItems: 'center' }} color="text.primary">
          Chat pedido de ajuda
        </Typography>
      </Breadcrumbs>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        sx={{ '@media(max-width: 960px)': { flexDirection: 'column' } }}
        gap={2}>
        <Typography variant="h3" sx={{ '@media(max-width: 960px)': { fontSize: 16 } }}>
          Chat com {helpData?.usuario.username}
        </Typography>
        <Box display={'flex'} flexDirection={'row'} gap={2}>
          <Button
            variant="outlined"
            onClick={() => getMessages(chatId)}
            sx={{ '@media(max-width: 960px)': { fontSize: 12, height: 35 } }}>
            Atualizar
          </Button>
          <Button
            variant="outlined"
            color="error"
            disabled={finalized}
            sx={{ '@media(max-width: 960px)': { fontSize: 12, height: 35 } }}
            onClick={() => finalizeChat()}>
            Finalizar Contato
          </Button>
        </Box>
      </Box>
      <Box
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
        sx={{ '@media(max-width: 960px)': { flexDirection: 'column' } }}
        gap={2}>
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{ width: '20%', '@media(max-width: 960px)': { width: '100%' } }}>
          <Typography variant="h6">Detalhes do contato</Typography>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} marginTop={2}>
            <PersonIcon sx={{ mr: 1 }} color="secondary" fontSize="medium" />
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant="body1">{helpData?.usuario?.username}</Typography>
              <Typography variant="caption">Usuário</Typography>
            </Box>
          </Box>
          <Box display={'flex'} flexDirection={'row'} alignItems={'center'} marginTop={2}>
            <ApartmentIcon color="secondary" sx={{ mr: 1 }} fontSize="medium" />
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant="body1">{helpData?.empresa}</Typography>
              <Typography variant="caption">Empresa</Typography>
            </Box>
          </Box>

          <Typography variant="h6" marginTop={2} marginBottom={1}>
            Descrição
          </Typography>
          <Box
            maxWidth={200}
            sx={{ border: 1, borderColor: 'grey.400', padding: 1, borderRadius: 1 }}>
            <Typography variant="body2">{helpData?.descricao}</Typography>
          </Box>
        </Box>
        <Box
          display={'flex'}
          flexDirection={'column'}
          sx={{
            width: '80%',
            height: '70vh',
            backgroundColor: 'rgba(156, 39, 176, 0.1)',
            '@media(max-width: 960px)': { width: '100%' }
          }}>
          <Chat messages={messages?.mensagens} sendMessage={sendMessage} finalized={finalized} />
        </Box>
      </Box>
    </Box>
  )
}
