import api from './api'

export interface ICompanyPositionService {
  getAllCompanyPosition: (params: any) => Promise<any>
  putCompanyPosition: (params: any) => Promise<any>
  postCompanyPosition: (params: any) => Promise<any>
}

export const companyPositionServices = <ICompanyPositionService>{
  getAllCompanyPosition: async (params: any) => {
    try {
      const { data } = await api.get('/auth/empresa-cargos', { params })

      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar cargos')
    }
  },
  putCompanyPosition: async (params: any) => {
    try {
      const { data } = await api.put(`/auth/empresa-cargos/${params.id}`, params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao atualizar cargo')
    }
  },
  postCompanyPosition: async (params: any) => {
    try {
      const { data } = await api.post('/auth/empresa-cargos', params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao cadastrar cargo')
    }
  }
}
