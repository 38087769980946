import { Box, Typography } from '@mui/material'
import icon from '../../assets/home/icon.svg'
import { Height } from '@mui/icons-material'

const EmptyIndicator = ({ text }: any) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 340,
        px: 2
      }}>
      <Box component={'img'} src={icon} sx={{ width: 20, mb: 2 }} />
      <Typography variant="h6" sx={{ fontSize: 14, color: '#00000099', textAlign: 'center' }}>
        {text}
      </Typography>
    </Box>
  )
}

export default EmptyIndicator
