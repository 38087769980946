import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import * as z from 'zod'

import CloseIcon from '@mui/icons-material/Close'
import React, { useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { enqueueSnackbar } from 'notistack'
import { AuthContext } from '../../contexts/auth/AuthContext'
import { companySectorServices } from '../../services/companySectorServices'
import { companyStructureServices } from '../../services/companySectorServices'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'

const schema = z.object({
  nome: z.string().min(1, 'Campo obrigatório'),
  empresa_setor_pai: z.string().optional(),
  empresa_estrutura: z.string().optional(),
  situacao: z.string().min(1, 'Campo obrigatório')
})

type Schema = z.infer<typeof schema>

interface CompanyFormProps {
  formData: any
  setOpen: (value: boolean) => void
}

export const CompanySectorForm = ({ formData, setOpen }: CompanyFormProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue
  } = useForm<Schema>({
    resolver: zodResolver(schema)
  })

  const queryClient = useQueryClient()
  const auth = useContext(AuthContext)

  const { mutate: putCompanyBranch, isLoading: isLoadingPut } = useMutation(
    async (data: any) => {
      return await companySectorServices.putCompanySector(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companySector')
        enqueueSnackbar('Salvo com sucesso!', { variant: 'success' })
        setOpen(false)
      }
    }
  )

  const { mutate: postCompanyBranch, isLoading: isLoadingPost } = useMutation(
    async (data: Schema) => {
      return await companySectorServices.postCompanySector(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('companySector')
        enqueueSnackbar('Salvo com sucesso!', { variant: 'success' })
        setOpen(false)
      }
    }
  )

  const sectors = queryClient.getQueryData<any>('companySector')

  //------------structures
  const [inputValue, setInputValue] = useState('')
  const [selectedOption, setSelectedOption] = useState<any>(null)
  const [options, setOptions] = useState<Option[]>([])

  const { data: optionsData } = useQuery(
    'options',
    async () => {
      return await companyStructureServices.getAllCompanyStructure({
        empresa: auth.user?.empresa,
        situacao: 'ATIVO',
        removido: false
      })
    },
    {
      onSuccess: (data) => {
        setSelectedOption(null)
        setOptions([])
        data.map((item: any) => {
          if (selectedOption === null) {
            item.id === formData?.empresa_estrutura &&
              setSelectedOption({ id: item.id, nome: item.nome })
          }
          setOptions((prev) => [...prev, { id: item.id, nome: item.nome }])
        })
      },
      refetchOnWindowFocus: false
    }
  )

  const { mutate: postCompanyStructure, isLoading: isLoadingPostStructure } = useMutation(
    async (data: any) => {
      return await companyStructureServices.postCompanyStructure(data)
    },
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('options').then(() => {
          setSelectedOption({
            id: res?.id,
            nome: res?.nome
          })
          setInputValue(res?.nome || '')
          enqueueSnackbar('Criado com sucesso!', { variant: 'success' })
        })
      }
    }
  )

  const { mutate: deleteCompanyStructure, isLoading: isLoadingDeleteStructure } = useMutation(
    async (data: any) => {
      return await companyStructureServices.deleteCompanyStructure(data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('options')
        setSelectedOption({ id: '', nome: '' })
        setInputValue('')
        enqueueSnackbar('Deletado com sucesso!', { variant: 'success' })
      }
    }
  )

  type Option = {
    id: string
    nome: string
  }

  const handleDeleteOption = (option: any) => {
    deleteCompanyStructure({ id: option.id })
  }

  const handleAddOption = (option: any) => {
    const payload = {
      nome: option.nome,
      empresa: auth.user?.empresa,
      situacao: 'ATIVO'
    }

    postCompanyStructure(payload)
  }

  const handleInputChange = (event: any) => {
    if (event) {
      setInputValue(event.target.value)
    } else {
      setInputValue(selectedOption?.nome || '')
    }
  }

  const handleOptionChange = (event: React.SyntheticEvent, newValue: Option | null) => {
    setSelectedOption(newValue)
  }

  const filter = createFilterOptions()

  const onSubmit = (data: Schema) => {
    if (data?.empresa_estrutura === 'add') {
      const payload = {
        nome: inputValue,
        empresa: auth.user?.empresa,
        situacao: 'ATIVO'
      }
      companyStructureServices.postCompanyStructure(payload).then((res) => {
        queryClient.invalidateQueries('options').then(() => {
          setSelectedOption({
            id: res?.id,
            nome: res?.nome
          })
          setInputValue(res?.nome || '')
        })
        putCompanyBranch({
          ...data,
          empresa_estrutura: res?.id,
          empresa: auth.user?.empresa,
          id: formData?._id
        })
      })
      return
    } else {
      const payloadCreate = {
        ...data,
        empresa: auth.user?.empresa
      }

      const payloadPut = {
        ...data,
        id: formData?._id,
        empresa: auth.user?.empresa,
        empresa_estrutura: selectedOption?.id
      }

      formData?._id ? putCompanyBranch(payloadPut) : postCompanyBranch(payloadCreate)
    }
  }

  return (
    <Dialog open={true} maxWidth="md">
      <DialogTitle variant="h4" sx={{ mt: 2 }}>
        {` ${formData?._id ? 'Edição de departamento' : 'Cadastro de departamento'}`}
      </DialogTitle>
      <IconButton
        sx={{ position: 'absolute', right: 15, top: 25 }}
        onClick={() => {
          setOpen(false)
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Nome"
                variant="outlined"
                fullWidth
                defaultValue={formData?.nome || ''}
                {...register('nome')}
                error={!!errors.nome}
                helperText={errors.nome?.message}
                key={formData?.nome || ''}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                label="Departamento pai"
                variant="outlined"
                fullWidth
                select
                defaultValue={formData?.empresa_setor_pai?._id || ''}
                {...register('empresa_setor_pai')}
                error={!!errors.empresa_setor_pai}
                helperText={errors.empresa_setor_pai?.message}>
                {sectors?.map((sector: any) => (
                  <MenuItem key={sector?._id} value={sector?._id}>
                    {sector?.nome || ''}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={6}>
              <>
                <Autocomplete
                  value={selectedOption}
                  options={options || []}
                  onChange={handleOptionChange}
                  getOptionLabel={(option) => option.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Estrutura Hierárquica"
                      placeholder="Selecione ou digite para adicionar"
                      variant="outlined"
                      onChange={handleInputChange}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      {option.nome}
                      {option.id === 'add' ? (
                        <Button size="small" onClick={() => handleAddOption(option)}>
                          Adicionar
                        </Button>
                      ) : (
                        <Button size="small" onClick={() => handleDeleteOption(option)}>
                          Deletar
                        </Button>
                      )}
                    </li>
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)

                    const { inputValue } = params
                    if (inputValue !== '') {
                      filtered.push({
                        id: 'add',
                        nome: inputValue
                      })
                      setValue('empresa_estrutura', 'add')
                    }
                    return filtered
                  }}
                  onInputChange={handleInputChange}
                  inputValue={inputValue}
                  freeSolo
                  disableClearable
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <div {...getTagProps({ index })} key={option.nome}>
                        {option.id}
                      </div>
                    ))
                  }
                  popupIcon={null}
                />
              </>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Situação"
                variant="outlined"
                fullWidth
                select
                defaultValue={formData?.situacao || 'ATIVO'}
                {...register('situacao')}
                error={!!errors.situacao}
                helperText={errors.situacao?.message}>
                <MenuItem value="ATIVO">Ativo</MenuItem>
                <MenuItem value="INATIVO">Inativo</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={12} sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                sx={{
                  mr: 2,
                  '&.Mui-focusVisible': {
                    transform: 'scale(1.05)',
                    transition: 'all 0.3s'
                  }
                }}
                type="submit">
                Salvar
              </Button>
              <Button
                variant="outlined"
                sx={{
                  '&.Mui-focusVisible': {
                    transform: 'scale(1.05)',
                    transition: 'all 0.3s'
                  }
                }}
                onClick={() => setOpen(false)}>
                Cancelar
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
