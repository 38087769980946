import { useContext } from 'react'
import { AuthContext } from '../contexts/auth/AuthContext'
import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'

export const Routes = () => {
  const { signed } = useContext(AuthContext)

  return signed ? <PrivateRoutes /> : <PublicRoutes />
}
