import { useState, useRef, useEffect } from 'react'
import { Box, Button, TextField } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'

type MessageType = {
  message: string
}

const UserMessage = ({ message }: MessageType) => {
  return (
    <Box display={'flex'} justifyContent={'flex-end'} marginBottom={2}>
      <Box
        sx={{
          paddingX: 2,
          paddingY: 2,
          backgroundColor: '#BA68C8',
          borderTopLeftRadius: 20,
          borderBottomLeftRadius: 20,
          borderTopRightRadius: 5,
          borderBottomRightRadius: 20,
          width: '45%',
          color: '#fff'
        }}>
        {message}
      </Box>
    </Box>
  )
}
const AdminMessage = ({ message }: MessageType) => {
  return (
    <Box display={'flex'} justifyContent={'flex-start'} marginBottom={2}>
      <Box
        sx={{
          paddingX: 2,
          paddingY: 2,
          backgroundColor: '#d9d9d9',
          borderTopLeftRadius: 2,
          borderBottomLeftRadius: 20,
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
          width: '45%'
        }}>
        {message}
      </Box>
    </Box>
  )
}

export const Chat = ({ messages, sendMessage, finalized }: any) => {
  const [messageText, setMessageText] = useState('')
  const scrollRef = useRef(null)

  useEffect(() => {
    if (scrollRef.current !== null) {
      // @ts-ignore
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight
    }
  }, [messages])

  return (
    <Box
      sx={{ height: '100%', padding: 0 }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}>
      <Box ref={scrollRef} sx={{ height: '100%', padding: 1, overflowY: 'scroll' }}>
        {messages?.map((message: any, index: number) => {
          if (message.sender === 'admin') {
            return <AdminMessage key={index} message={message.message} />
          } else {
            return <UserMessage key={index} message={message.message} />
          }
        })}
      </Box>
      <Box
        sx={{
          backgroundColor: '#fff',
          borderRadius: 1,
          height: 75,
          paddingX: 2,
          marginX: 1,
          mb: 1,
          boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)'
        }}
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <TextField
          variant="outlined"
          sx={{
            '& fieldset': { border: 'none' }
          }}
          placeholder="Digite sua mensagem..."
          fullWidth
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              if (messageText === '') return
              sendMessage(messageText)
              setMessageText('')
            }
          }}
          disabled={finalized && messages !== undefined}
          value={messageText}
          onChange={(e) => {
            setMessageText(e.target.value)
          }}
          // defaultValue={helpRequestData?.codigo || ''}
        />

        <Button
          variant="contained"
          color="primary"
          sx={{ height: 40, ml: 2 }}
          endIcon={<SendIcon />}
          disabled={finalized && messages !== undefined}
          onClick={() => {
            sendMessage(messageText)
            setMessageText('')
          }}>
          Enviar
        </Button>
      </Box>
    </Box>
  )
}
