import api from './api'

export interface IdashboardClientServices {
  usersWithActiveJourney: () => Promise<any>
  getHealthArea: () => Promise<any>
  getUsersInAttendance: () => Promise<any>
  getDiagnosticHypothesis: () => Promise<any>
  getTests: () => Promise<any>
  getEngagement: () => Promise<any>
  highligthDiagnosticSector: () => Promise<any>
  highligthHelpService: () => Promise<any>
}

export const dashboardClientServices = <IdashboardClientServices>{
  usersWithActiveJourney: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/usuarios-jornadas-ativas')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar usuários com jornada ativa')
    }
  },
  getHealthArea: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/area-saude')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar área de saúde')
    }
  },
  getUsersInAttendance: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/usuarios-em-atendimento')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar usuários em atendimento')
    }
  },
  getDiagnosticHypothesis: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/diagnostico')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar diagnóstico')
    }
  },
  getTests: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/testes')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar testes')
    }
  },
  getEngagement: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/engajamento-geral')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar engajamento')
    }
  },
  highligthDiagnosticSector: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/destaque-setor-diagnostico')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar destaque diagnóstico')
    }
  },
  highligthHelpService: async () => {
    try {
      const { data } = await api.get('/auth/dashboard-clientes/destaque-servico-ajuda')
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar destaque serviço de ajuda')
    }
  }
}
