import { Modal, Box, Card, Avatar, Typography, Button } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

type ModalDeleteProps = {
  open: boolean
  onClose: () => void
  message: string
  remove: any
}

export const ModalDelete = (props: ModalDeleteProps) => {
  const { open, onClose, message, remove } = props

  const handleDelete = async () => {
    await remove()
    onClose()
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Box
        sx={{
          position: 'relative',
          outline: 0
        }}>
        <Card
          sx={{
            outline: 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            p: 4,
            pt: 10
          }}>
          <Avatar
            sx={{
              bgcolor: '#FF9800',
              position: 'absolute',
              width: 100,
              height: 100,
              top: -50,
              left: 'calc(50% - 50px)'
            }}>
            <WarningAmberIcon sx={{ width: 40, height: 40 }} />
          </Avatar>
          <Typography variant="h4">{message}</Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            Esta ação não poderá ser desfeita.
          </Typography>
          <Box sx={{ mt: 3 }}>
            <Button sx={{ mr: 2 }} onClick={onClose}>
              Cancelar
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete}>
              Remover
            </Button>
          </Box>
        </Card>
      </Box>
    </Modal>
  )
}
