import api from './api'

export interface IHelpRequestsService {
  getChatById: (id: any) => Promise<any>
  getChatHelpRequest: (params: any) => Promise<any>
  sendNewMessage: (id: any, params: any) => Promise<any>
  createChat: (params: any) => Promise<any>
  putOneChat: (id: any, params: any) => Promise<any>
}

export const chatServices = <IHelpRequestsService>{
  getChatById: async (id: string) => {
    try {
      const { data } = await api.get(`/auth/chats/${id}`)
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar chat')
    }
  },
  getChatHelpRequest: async (params: any) => {
    try {
      const { data } = await api.get(`/auth/chat-pedido-ajudas`, { params })
      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar chat')
    }
  },
  sendNewMessage: async (id: any, params: any) => {
    try {
      const { data } = await api.put(`/auth/chats-nova-mensagem/${id}`, params)
      return data.response
    } catch (e) {
      throw new Error('Erro ao enviar mensagem')
    }
  },
  createChat: async (params: any) => {
    try {
      const { data } = await api.post('/auth/chats', params)
      return data.response
    } catch (e) {
      throw new Error('Erro ao enviar nova mensagem')
    }
  },
  putOneChat: async (id: any, params: any) => {
    try {
      const { data } = await api.put(`/auth/chats/${id}`, params)
      return data.response
    } catch (e) {
      throw new Error('Erro ao editar mensagem')
    }
  }
}
