import api from './api'

export interface ICompanySectorService {
  getAllCompanySector: (params: any) => Promise<any>
  putCompanySector: (params: any) => Promise<any>
  postCompanySector: (params: any) => Promise<any>
}

export interface ICompanyStructureService {
  getAllCompanyStructure: (params: any) => Promise<any>
  putCompanyStructure: (params: any) => Promise<any>
  postCompanyStructure: (params: any) => Promise<any>
  deleteCompanyStructure: (params: any) => Promise<any>
}

export const companySectorServices = <ICompanySectorService>{
  getAllCompanySector: async (params: any) => {
    try {
      const { data } = await api.get('/auth/empresa-setores', { params })

      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar setores')
    }
  },
  putCompanySector: async (params: any) => {
    try {
      const { data } = await api.put(`/auth/empresa-setores/${params.id}`, params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao atualizar setor')
    }
  },
  postCompanySector: async (params: any) => {
    try {
      const { data } = await api.post('/auth/empresa-setores', params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao cadastrar setor')
    }
  }
}

export const companyStructureServices = <ICompanyStructureService>{
  getAllCompanyStructure: async (params: any) => {
    try {
      const { data } = await api.get('/auth/empresa-estruturas', { params })

      return data.response
    } catch (error) {
      throw new Error('Erro ao buscar estruturas')
    }
  },
  putCompanyStructure: async (params: any) => {
    try {
      const { data } = await api.put(`/auth/empresa-estruturas/${params.id}`, params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao atualizar estrutura')
    }
  },
  postCompanyStructure: async (params: any) => {
    try {
      const { data } = await api.post('/auth/empresa-estruturas', params)
      return data.response
    } catch (error) {
      throw new Error('Erro ao cadastrar estrutura')
    }
  },
  deleteCompanyStructure: async (params: any) => {
    try {
      const { data } = await api.delete(`/auth/empresa-estruturas/${params.id}`)
      return data.response
    } catch (error) {
      throw new Error('Erro ao excluir estrutura')
    }
  }
}
